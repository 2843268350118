import { useEffect, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import axios from "axios";
import * as moment from "moment-timezone";
import momentCore from "moment";
import {
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip
} from "reactstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import { ArcElement, Chart as ChartJS, Legend as ChartJSLegend, Tooltip as ChartJSTooltip } from "chart.js";
import _ from "lodash";
import classNames from "classnames";
import LoadingBar from "react-top-loading-bar";
import { COLORS } from "../constants/colors";
import { abbreviatedNumber } from "../helpers/formatNumber";
import { getRandomColor } from "../helpers/randomColor";
ChartJS.register(ArcElement, ChartJSTooltip, ChartJSLegend);
const animatedComponents = makeAnimated();

let controller: AbortController;
let apiHitCount = 0;
let apiCount = 0;

export default function CompareChart() {
  const totalApis = 10;

  const [chains, setChains] = useState<any>(null);
  const [cinemas, setCinemas] = useState<any>(null);
  const [movies, setMovies] = useState<any>(null);
  const [states, setStates] = useState<any>(null);
  const [cities, setCities] = useState<any>(null);
  const [cinemaStates, setCinemaStates] = useState<any>(null);
  const [cinemaCities, setCinemaCities] = useState<any>(null);
  const [cinemaCountries, setCinemaCountries] = useState<string[]>([]);
  const [selectedCinemaCountries, setSelectedCinemaCountries] = useState<string[]>([]);
  const [selectedStates, setSelectedStates] = useState<string[]>([]);
  const [selectedCinemaCities, setSelectedCinemaCities] = useState<string[]>([]);
  const [selectedChainIds, setSelectedChainIds] = useState<number[]>([]);
  const [selectedCinemaIds, setSelectedCinemaIds] = useState<number[]>([]);
  const [selectedMovieIds, setSelectedMovieIds] = useState<number[]>([]);
  const [showtimeDateRange, setShowtimeDateRange] = useState([null, null]);
  const [showtimeInsightData, setShowtimeInsightData] = useState<any>([]);
  const [showtimeInsightLabel, setShowtimeInsightLabel] = useState<any>([]);
  const [seatPurchaseData, setSeatPurchaseData] = useState<any>([]);
  const [seatPurchaseLabel, setSeatPurchaseLabel] = useState<any>([]);
  const [occupancyInsightData, setOccupancyInsightData] = useState<any>([]);
  const [occupancyInsightLabel, setOccupancyInsightLabel] = useState<any>([]);
  const [occupancyInsightColor, setOccupancyInsightColor] = useState<any>([]);
  const [revenueInsightData, setRevenueInsightData] = useState<any>([]);
  const [revenueInsightLabel, setRevenueInsightLabel] = useState<any>([]);
  const [revenueInsightColor, setRevenueInsightColor] = useState<any>([]);
  const [apiCountState, setApiCountState] = useState<any>(0);

  let { apikey } = useParams();
  let BASE_URL = "";
  if (typeof window !== "undefined" && (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")) {
    BASE_URL = "http://localhost:3030/api/v1";
  } else {
    BASE_URL = "https://api.screensights.io/api/v1";
  }
  const commonHeaders = {
    "X-Api-Key": apikey
  };
  const buildFilterParam = (exclude?: string, extraFilters: any = {}) => {
    return {
      ...(exclude === "chain_ids" ? {} : { chain_ids: selectedChainIds }),
      ...(exclude === "cinema_ids" ? {} : { cinema_ids: selectedCinemaIds }),
      ...(exclude === "cinema_countries" ? {} : { cinema_countries: selectedCinemaCountries }),
      ...(exclude === "cinema_cities" ? {} : { cinema_cities: selectedCinemaCities }),
      ...(exclude === "cinema_states" ? {} : { cinema_states: selectedStates }),
      ...(exclude === "movie_ids" ? {} : { movie_ids: selectedMovieIds }),
      showtime_from: showtimeDateRange?.[0]
        ? momentCore(showtimeDateRange?.[0]).format("YYYY-MM-DD")
        : undefined,
      showtime_to: showtimeDateRange?.[1]
        ? momentCore(showtimeDateRange?.[1]).format("YYYY-MM-DD")
        : undefined,
      cache: false,
      limit: 1000,
      ...extraFilters
    };
  };

  const fetchAllData = () => {
    try {
      apiCount = 0;
      setApiCountState(0);
      apiHitCount += 1;
      controller = new AbortController();
      fetchFromAPI("/dashboard/chains", "chain_ids", setChains, {}, apiHitCount, undefined, true);
      fetchFromAPI("/dashboard/cinemas", "cinema_ids", setCinemas, {}, apiHitCount, undefined, true);
      fetchFromAPI("/dashboard/movies", "movie_ids", setMovies, {}, apiHitCount, undefined, true);
      fetchFromAPI("/dashboard/cinema-countries", "cinema_countries", setCinemaCountries, {}, apiHitCount, undefined, true);
      fetchFromAPI("/dashboard/cinema-states", "cinema_states", setStates, {}, apiHitCount, undefined, true);
      fetchFromAPI("/dashboard/cinema-cities", "cinema_cities", setCities, {}, apiHitCount, undefined, true);
      fetchFromAPI("/dashboard/showtime-insight", "", setShowtimeInsightData, {limit: 10000}, apiHitCount, undefined, true);
      fetchFromAPI("/dashboard/seat-purchase-insight", "", setSeatPurchaseData, {limit: 10000}, apiHitCount, undefined, true);
      fetchFromAPI("/dashboard/occupancy-insight", "", setOccupancyInsightData, {limit: 10000}, apiHitCount, undefined, true);
      fetchFromAPI("/dashboard/revenue-insight", "", setRevenueInsightData, {limit: 10000}, apiHitCount, undefined, true);

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, [
    selectedChainIds,
    selectedCinemaIds,
    selectedCinemaCountries,
    selectedCinemaCities,
    selectedStates,
    selectedMovieIds,
    showtimeDateRange
  ]);

  useEffect(() => {
    const formatedStates = states?.filter((state: any) => state.cinema_state !== null && state.cinema_state !== 0)
    const formatedCities = cities?.filter((city: any) => city.cinema_city !== null && city.cinema_city !== 0)
    console.log(formatedCities)
    console.log(formatedStates)
    setCinemaStates(formatedStates)
    setCinemaCities(formatedCities)
  }, [states, cities])

  useEffect(() => {
    calculateChartData();
  }, [showtimeInsightData, seatPurchaseData, occupancyInsightData, revenueInsightData]);

  const calculateChartData = () => {
    if (!_.isEmpty(selectedChainIds) && _.isEmpty(selectedMovieIds) && _.isEmpty(selectedCinemaCountries) && _.isEmpty(selectedStates) && _.isEmpty(selectedCinemaIds) && _.isEmpty(selectedCinemaCities)) {

      const selectedChainNames = _.chain(chains)
        .filter(chain => selectedChainIds.includes(chain.chain_id))
        .map("chain_name")
        .value();

      setShowtimeInsightLabel(selectedChainNames);
      setSeatPurchaseLabel(selectedChainNames);
      setOccupancyInsightLabel(_.flatMap(selectedChainNames, chain => [
        `${chain}_total_seats`,
        `${chain}_occupied_seats`
      ]));
      setRevenueInsightLabel(_.flatMap(selectedChainNames, chain => [
        `${chain}_potential_revenue`,
        `${chain}_estimated_revenue`
      ]));
    } else {
      setShowtimeInsightLabel(getLabels(showtimeInsightData));
      setSeatPurchaseLabel(getLabels(seatPurchaseData));
      setOccupancyInsightLabel(getLabels(occupancyInsightData));
      setRevenueInsightLabel(getLabels(revenueInsightData));
    }
  };

  useEffect(() => {
    let colorArray = convertToColorArray(occupancyInsightLabel);
    setOccupancyInsightColor(colorArray);
    colorArray = convertToColorArray(revenueInsightLabel);
    setRevenueInsightColor(colorArray);
  }, [occupancyInsightLabel]);

  const fetchFromAPI = (
    endpoint: string,
    excludeParam: string,
    cb: Function,
    extraFilters = {},
    _apiHitCount: number,
    cbDraw?: Function,
    updateApiCountState?: boolean
  ) => {
    (function tryFetch(retry: number = 1) {
      axios
        .get(`${BASE_URL}${endpoint}`, {
          withCredentials: false,
          headers: {
            ...commonHeaders
          },
          signal: controller.signal,
          timeout: 5 * 60 * 1000,
          params: buildFilterParam(excludeParam, extraFilters)
        })
        .then(function ({ data }) {
          if (_apiHitCount === 0 || _apiHitCount === apiHitCount) {
            cb(processNumbers(data));
            if (cbDraw) {
              cbDraw(apiHitCount);
            }
            if (updateApiCountState) {
              apiCount += 1;
              setApiCountState(apiCount);
            }
          }
        })
        .catch(function (error) {
          if (!axios.isCancel(error)) {
            console.error(error);
            if (retry <= 3) {
              setTimeout(function () {
                tryFetch(retry++);
              }, _.random(1000, 10000));
            }
          }
        });
    })();
  };

  function processNumbers(inputData: any) {
    if (Array.isArray(inputData)) {
      inputData.forEach(item => processNumbers(item));
    } else if (typeof inputData === "object" && inputData !== null) {
      for (let key in inputData) {
        if (inputData.hasOwnProperty(key)) {
          let value = inputData[key];
          let parsedValue = Number(value);
          if (!isNaN(parsedValue) && isFinite(value)) {
            inputData[key] = parsedValue;
          }
        }
      }
    }
    return inputData;
  }

  const getLabels = (data: any) => {
    const uniqueCinemas = new Set();

    data.forEach((item: any) => {
      Object.keys(item).forEach(key => {
        if (key !== '_showtime_date') {
          uniqueCinemas.add(key);
        }
      });
    });

    return Array.from(uniqueCinemas);
  };

  const convertToColorArray = (labels: any) => {
    const colorMap: any = {};

    const colorArray = labels.map((label: any, index: number) => {
      const parts = label.split("_");
      const baseLabel = parts[0];

      if (!colorMap[baseLabel]) {
        colorMap[baseLabel] = getColor(index);
      }

      return {
        label: label,
        color: colorMap[baseLabel]
      };
    });

    return _.map(colorArray, "color");
  };

  const getColor = (index: number) => {
    const color = COLORS[index];
    return color !== undefined ? color : getRandomColor();
  };

  const renderLegend = (props: any) => {
    const { payload } = props;
    return (
      <ul>
        {payload.map(
          (entry: any, index: number) =>
            (entry.value.includes("_total_seats") || entry.value.includes("_potential_revenue")) && (
              <li style={{ display: "inline", marginRight: "8px" }}>
                <svg
                  className="recharts-surface"
                  width="14"
                  height="14"
                  viewBox="0 0 32 32"
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: "4px"
                  }}
                >
                  <title></title>
                  <desc></desc>
                  <path
                    strokeWidth="4"
                    fill="none"
                    stroke={entry.color}
                    d="M0,16h10.666666666666666
                A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
                H32M21.333333333333332,16
                A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"
                    className="recharts-legend-icon"
                  ></path>
                </svg>
                <span style={{ color: entry.color }}>{entry.value.split("_")[0]}</span>
              </li>
            )
        )}
      </ul>
    );
  };

  const renderRevenueTooltip = ({ active, payload, label }: any) => {
    if (active && payload && label) {
      return (
        <div
          className="recharts-default-tooltip"
          style={{
            margin: "0px",
            padding: "10px",
            backgroundColor: "rgb(255, 255, 255)",
            border: "1px solid rgb(204, 204, 204)",
            whiteSpace: "nowrap"
          }}
        >
          <p className="recharts-tooltip-label" style={{ margin: "0px" }}>
            {label}
          </p>
          <ul
            className="recharts-tooltip-item-list"
            style={{ padding: "0px", margin: "0px" }}
          >
            {payload.map((item: any, index: number) => {
              if (item.name.includes("_potential_revenue")) {
                return (
                  <li
                    key={index}
                    className="recharts-tooltip-item"
                    style={{
                      display: "block",
                      paddingTop: "4px",
                      paddingBottom: "4px",
                      color: item.color
                    }}
                  >
                    <span className="recharts-tooltip-item-name">{item.name.split("_")[0]}</span>
                    <span className="recharts-tooltip-item-separator"> : </span> <br />
                    <ul>
                      <li>
                        <span
                          className="recharts-tooltip-item-value">Estimated Revenue {item.name.includes("Other Cinemas") ? '≈' : ':'} {abbreviatedNumber(findRelateItem(payload, `${item.name.split("_")[0]}_estimated_revenue`))} {findRelateCurrency(`${item.name.split("_")[0]}_currency`)}</span>
                      </li>
                      <li>
                        <span
                          className="recharts-tooltip-item-value">Potential Revenue {item.name.includes("Other Cinemas") ? '≈' : ':'} {abbreviatedNumber(item.value)} {findRelateCurrency(`${item.name.split("_")[0]}_currency`)}</span>
                      </li>
                    </ul>
                    <span className="recharts-tooltip-item-unit"></span>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>
      );
    }
    return null;
  };

  const findRelateCurrency = (name: string) => {
    const foundObject = revenueInsightData.find((obj: any) => obj.hasOwnProperty(name));
    return foundObject ? foundObject[name] : "";
  };

  const renderOccupancyTooltip = ({ active, payload, label }: any) => {
    if (active && payload && label) {
      return (
        <div
          className="recharts-default-tooltip"
          style={{
            margin: "0px",
            padding: "10px",
            backgroundColor: "rgb(255, 255, 255)",
            border: "1px solid rgb(204, 204, 204)",
            whiteSpace: "nowrap"
          }}
        >
          <p className="recharts-tooltip-label" style={{ margin: "0px" }}>
            {label}
          </p>
          <ul
            className="recharts-tooltip-item-list"
            style={{ padding: "0px", margin: "0px" }}
          >
            {payload.map((item: any, index: number) => (
              item.name.includes("_total_seats") && (
                <li
                  key={index}
                  className="recharts-tooltip-item"
                  style={{
                    display: "block",
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    color: item.color
                  }}
                >
                  <span className="recharts-tooltip-item-name">{item.name.split("_")[0]}</span>
                  <span className="recharts-tooltip-item-separator"> : </span> <br />
                  <ul>
                    <li>
                      <span
                        className="recharts-tooltip-item-value">Occupied seats: {abbreviatedNumber(findRelateItem(payload, `${item.name.split("_")[0]}_occupied_seats`))}</span>
                    </li>
                    <li>
                      <span className="recharts-tooltip-item-value">Available seats: {abbreviatedNumber(item.value)}</span>
                    </li>
                  </ul>
                  <span className="recharts-tooltip-item-unit"></span>
                </li>
              )
            ))}
          </ul>
        </div>
      );
    }
    return null;
  };

  const findRelateItem = (payload: [], name: string) => {
    const item: any = payload.find((x: any) => x.name === name);
    return item.value;
  };

  const CustomToolTip = (props: any) => {
    const { active, payload, label } = props;
    if (!active || !payload) {
      return null;
    }
    return (
      <div
        className="custom-tooltip"
      >
        <p>
          {label}
        </p>
        {payload.map((item: any, i: any) => (
          <p key={i} style={{color: item.color}}>
            {item.name}: {abbreviatedNumber(item.value)}
          </p>
        ))}
      </div>
    );
  };

  console.log(showtimeInsightData)

  return (
    <>
      <LoadingBar
        color="#0072f0"
        progress={Math.round((apiCountState / totalApis) * 100)}
        onLoaderFinished={() => {
          apiCount = 0;
          setApiCountState(0);
        }}
        style={{ height: "5px" }}
      />
      <Container fluid={true} className="px-lg-5 mt-5">
        <Row>
          <Col xs={12}>
            <h2 className="chart-group-title">Filters</h2>
          </Col>
        </Row>

        <Row className={"mb-5"}>
          <Col xs={12} sm={5}>
            <Row>
              <Col md={4} sm={12}>
                <FormGroup>
                  <Label>Movie</Label>
                  <Select
                    isMulti
                    onChange={(value: any) => {
                      if (controller) {
                        controller.abort("Parameter changed");
                      }
                      const selectedMovie = Array.isArray(value) ? value?.map((v: any) => v?.value) : [];
                      setSelectedMovieIds(selectedMovie);
                    }}
                    options={
                      movies?.map((movie: any) => ({
                        value: movie.movie_id,
                        label: `${movie.movie_title} (${movie?._total_showtimes})`
                      })) || []
                    }
                    components={animatedComponents}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={12}>
                <FormGroup>
                  <Label>Country</Label>
                  <Select onChange={(value) => {
                    if (controller) {
                      controller.abort("Parameter changed");
                    }
                    setSelectedCinemaCountries((value as any[])?.map((v: any) => v?.value) || []);
                  }} options={cinemaCountries?.map((cinemaCountry: any) => ({
                    value: cinemaCountry.cinema_country,
                    label: `${cinemaCountry.cinema_country} (${cinemaCountry?._total_showtimes})`
                  })) || []}
                    components={animatedComponents} isMulti />
                </FormGroup>
              </Col>
              <Col md={4} sm={12}>
                <FormGroup>
                  <Label>State</Label>
                  <Select
                    isMulti
                    onChange={(value: any) => {
                      if (controller) {
                        controller.abort("Parameter changed");
                      }
                      setSelectedStates((value as any[])?.map((v: any) => v?.value) || []);
                    }}
                    options={cinemaStates?.filter((state: any) => state.cinema_state !== null)?.map((state: any) => ({
                      value: state.cinema_state,
                      label: `${state.cinema_state} (${state?._total_showtimes})`
                    })) || []}
                    components={animatedComponents}
                  />
                </FormGroup>
              </Col>

            </Row>
          </Col>

          <Col xs={12} sm={5}>
            <Row>
              <Col md={4} sm={12}>
                <FormGroup>
                  <Label>City</Label>
                  <Select
                    isMulti
                    onChange={(value: any) => {
                      if (controller) {
                        controller.abort("Parameter changed");
                      }
                      setSelectedCinemaCities((value as any[])?.map((v: any) => v?.value) || []);
                    }}
                    options={cinemaCities?.map((city: any) => ({
                      value: city.cinema_city,
                      label: `${city.cinema_city} (${city?._total_showtimes})`
                    })) || []}
                    components={animatedComponents}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={12}>
                <FormGroup>
                  <Label>Chain</Label>
                  <Select
                    isMulti
                    onChange={(value: any) => {
                      if (controller) {
                        controller.abort("Parameter changed");
                      }
                      setSelectedChainIds((value as any[])?.map((v: any) => v?.value) || []);
                    }}
                    options={chains?.map((chain: any) => ({
                      value: chain?.chain_id,
                      label: `${chain.chain_name} (${chain?._total_showtimes})`
                    })) || []}
                    components={animatedComponents}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={12}>
                <FormGroup>
                  <Label>Cinema</Label>
                  <Select
                    isMulti
                    onChange={(value: any) => {
                      if (controller) {
                        controller.abort("Parameter changed");
                      }
                      setSelectedCinemaIds((value as any[])?.map((v: any) => v?.value) || []);
                    }}
                    options={cinemas?.map((cinema: any) => ({
                      value: cinema?.cinema_id,
                      label: `${cinema?.cinema_name} (${cinema?._total_showtimes})`
                    })) || []}
                    components={animatedComponents}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>

          <Col xs={12} sm={2}>
            <FormGroup>
              <Label>Range</Label>
              <DatePicker
                onChange={(date: any) => {
                  if (controller) {
                    controller.abort("Parameter changed");
                  }
                  setShowtimeDateRange(date);
                }}
                isClearable
                selectsRange={true}
                startDate={showtimeDateRange?.[0]}
                endDate={showtimeDateRange?.[1]}
                className="form-control compare-chart-range"
                placeholderText="Select date range"
              />
            </FormGroup>
          </Col>
        </Row>

        <Col md={12}>
          <h2 className="chart-group-title">Showtimes Insights</h2>
          <h4 className="chart-title">Showtimes Comparison</h4>
          <div className={classNames("chart-container large")}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={showtimeInsightData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="_showtime_date"
                  tickFormatter={_showtime_date => moment.utc(_showtime_date).format("MM/DD/YY")} angle={35}
                  tickMargin={15} height={60} tick={{ fontSize: 12 }} />
                <YAxis tickFormatter={tick => {return abbreviatedNumber(tick)}}/>
                <Tooltip wrapperStyle={{ pointerEvents: "auto" }} content={<CustomToolTip />}/>
                <Legend />
                {showtimeInsightLabel.map((label: any, index: number) => (
                  <Line
                    key={index}
                    type="monotone"
                    dataKey={label}
                    stroke={getColor(index)}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Col>

        <Col md={12}>
          <h2 className="chart-group-title">Seat Purchase Time Insights</h2>
          <h4 className="chart-title">Seat Purchase Time Comparison <span id="seat_purchase"
            className={"info-box"}>?</span></h4>
          <UncontrolledTooltip style={{ minWidth: "300px" }} placement="top" target="seat_purchase">
            The chart below illustrates the timeframe during which seats were booked.
          </UncontrolledTooltip>

          <div className={classNames("chart-container large")}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={seatPurchaseData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="_showtime_date"
                  tickFormatter={_showtime_date => moment.utc(_showtime_date).format("MM/DD/YY")} angle={35}
                  tickMargin={15} height={60} tick={{ fontSize: 12 }} />
                <YAxis tickFormatter={tick => {return abbreviatedNumber(tick)}}/>
                <Tooltip wrapperStyle={{ pointerEvents: "auto" }} content={<CustomToolTip />} />
                <Legend />
                {seatPurchaseLabel.map((label: any, index: number) => (
                  <Line
                    key={index}
                    type="monotone"
                    dataKey={label}
                    stroke={getColor(index)}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Col>
        <Col md={12}>
          <h2 className="chart-group-title">Occupancy Insights</h2>
          <h4 className="chart-title">Occupancy Comparison <span id="occupancy_comparison"
            className={"info-box"}>?</span></h4>
          <UncontrolledTooltip style={{ minWidth: "300px" }} placement="top" target="occupancy_comparison">
            The dashed line illustrates the total available seats, whereas the not dashed line illustrates the occupied seats
          </UncontrolledTooltip>
          <div className={classNames("chart-container huge")}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={occupancyInsightData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="_showtime_date"
                  tickFormatter={_showtime_date => moment.utc(_showtime_date).format("MM/DD/YY")} angle={35}
                  tickMargin={15} height={60} tick={{ fontSize: 12 }} />
                <YAxis tickFormatter={tick => {return abbreviatedNumber(tick)}}/>
                <Tooltip wrapperStyle={{ pointerEvents: "auto" }} content={renderOccupancyTooltip} />
                <Legend content={renderLegend} />
                {occupancyInsightLabel.map((label: any, index: number) => {
                  return (
                    label.includes("_total_seats") ?
                      <Line
                        key={index}
                        type="monotone"
                        dataKey={label}
                        stroke={occupancyInsightColor[index]}
                        strokeOpacity="1"
                        strokeDasharray={"5 5"}
                        name={label}
                      /> :
                      <Line
                        key={index}
                        type="monotone"
                        dataKey={label}
                        stroke={occupancyInsightColor[index]}
                        strokeOpacity="1"
                        strokeDasharray={0}
                        name={label}
                      />
                  )
                })}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Col>
        <Col md={12}>
          <h2 className="chart-group-title">Revenue Estimates Insights</h2>
          <h4 className="chart-title">Revenue Comparison <span id="revenue_comparison" className={"info-box"}>?</span>
          </h4>
          <UncontrolledTooltip style={{ minWidth: "300px" }} placement="top" target="revenue_comparison">
            The revenue comparison is calculated based on the total count of available and booked seats, based on your specified filter criteria, and by the seat or ticket price. The dashed line illustrates the potential revenue, whereas the not dashed line illustrates the estimated revenue. We prioritize using the actual seat price if available; otherwise, we use the first price listed in the cinema website's ticket price overview, which typically corresponds to the highest price. If you require customization in this pricing approach, we can accommodate your preferences upon request. Additionally, our API provides access to all available prices, allowing you to conduct your own pricing analysis.
          </UncontrolledTooltip>

          <div className={classNames("chart-container huge")}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={revenueInsightData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="_showtime_date"
                  tickFormatter={_showtime_date => moment.utc(_showtime_date).format("MM/DD/YY")} angle={35}
                  tickMargin={15} height={60} tick={{ fontSize: 12 }} />
                <YAxis tickFormatter={tick => {return abbreviatedNumber(tick)}}/>
                <Tooltip wrapperStyle={{ pointerEvents: "auto" }} content={renderRevenueTooltip} />
                <Legend content={renderLegend} />
                {revenueInsightLabel.map((label: any, index: number) => {
                  return (
                    label.includes("_potential_revenue") ?
                      <Line
                        key={index}
                        type="monotone"
                        dataKey={label}
                        stroke={revenueInsightColor[index]}
                        strokeOpacity="1"
                        strokeDasharray={"5 5"}
                        name={label}
                      /> :
                      <Line
                        key={index}
                        type="monotone"
                        dataKey={label}
                        stroke={revenueInsightColor[index]}
                        strokeOpacity="1"
                        strokeDasharray={0}
                        name={label}
                      />
                  )
                })}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Col>
      </Container>
    </>
  );
}
