import React, {useEffect, useState} from "react";
import {
    Bar,
    BarChart, Brush,
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';

import axios from "axios";
import * as moment from "moment-timezone";
import momentCore from "moment";
import {
    Col,
    Container,
    FormGroup,
    Label,
    Row,
    UncontrolledTooltip,
    Table,
    Pagination,
    PaginationItem, PaginationLink
} from "reactstrap";
import numeral from "numeral";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import {useParams, useSearchParams} from "react-router-dom";
import {ArcElement, Chart as ChartJS, Legend as ChartJSLegend, Tooltip as ChartJSTooltip} from "chart.js";
import {Pie as ChartJSPie} from "react-chartjs-2";
import _ from "lodash";
import classNames from "classnames";
import LoadingBar from "react-top-loading-bar";
import {SeatByCinemaLocation} from "../interfaces/seatByCinemaLocation";
import OccupancyHeatmap from "./OccupancyHeatmap";
import PieProgressBar from "./PieProgressBar";

ChartJS.register(ArcElement, ChartJSTooltip, ChartJSLegend);
const animatedComponents = makeAnimated();

function breakText(data: string) {
    const maxLength = 31;
    if (data.length > maxLength) {
        const regex = new RegExp(`(.{1,${maxLength}})(\\s|$)`, 'g');
        return data.match(regex)?.join('\n');
    }
    return data;
}

enum KPI_TYPE {
    released_showtimes = 'released_showtimes',
    admissions = 'admissions',
    pre_sales = 'pre_sales',
    demand_pressure_index = 'demand_pressure_index',
    booking_density = 'booking_density',
    booking_rate = 'booking_rate',
}

let controller: AbortController;
let apiHitCount = 0;
let apiCount = 0;

function DashboardV3() {
    const totalApis = 30;
    let {apikey} = useParams();
    let isDev = false
    let BASE_URL = "";
    if (typeof window !== "undefined" && (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")) {
        BASE_URL = "http://localhost:3030/api/v2";
        isDev = true
    } else {
        BASE_URL = "https://api.screensights.io/api/v2";
    }
    const commonHeaders = {
        "X-Api-Key": apikey
    };

    const [apiHitCountState, setApiHitCountState] = useState<number>(0);
    const [showtimeIsCanceled, setShowtimeIsCanceled] = useState<boolean>(false);
    const [kpiType, setKpiType] = useState<string>(KPI_TYPE.released_showtimes);
    const [movies, setMovies] = useState<any>(null);
    const [selectedMovieIds, setSelectedMovieIds] = useState<number[]>([]);
    const [chains, setChains] = useState<any>(null);
    const [selectedChainIds, setSelectedChainIds] = useState<number[]>([]);
    const [selectedAttributes, setSelectedAttributes] = useState<number[]>([]);
    const [cinemas, setCinemas] = useState<any>(null);
    const [screens, setScreens] = useState<any>(null);
    const [selectedScreenIds, setSelectedScreenIds] = useState<number[]>([]);
    const [attributes, setAttributes] = useState<any>(null);
    const [selectedCinemaIds, setSelectedCinemaIds] = useState<number[]>([]);
    const [cinemaCountries, setCinemaCountries] = useState<string[]>([]);
    const [selectedCinemaCountries, setSelectedCinemaCountries] = useState<string[]>([]);
    const [cinemaStates, setCinemaStates] = useState<string[]>([]);
    const [selectedCinemaStates, setSelectedCinemaStates] = useState<string[]>([]);
    const [cinemaCities, setCinemaCities] = useState<string[]>([]);
    const [selectedCinemaCities, setSelectedCinemaCities] = useState<string[]>([]);
    const [showtimeDateRange, setShowtimeDateRange] = useState([null, null]);
    const [occupancyDateRange, setOccupancyDateRange] = useState([null, null]);
    const [showtimeSummary, setShowtimeSummary] = useState<any>({});
    const [moviesPlayingInChains, setMoviesPlayingInChains] = useState<any>({});
    const [moviesPlayingInChainsDraw, setMoviesPlayingInChainsDraw] = useState<number>(0);
    const [moviesPlayingInCinemas, setMoviesPlayingInCinemas] = useState<any>({});
    const [moviesPlayingInCinemasDraw, setMoviesPlayingInCinemasDraw] = useState<number>(0);
    const [moviesPlayingInScreens, setMoviesPlayingInScreens] = useState<any>({});
    const [moviesPlayingInScreensDraw, setMoviesPlayingInScreensDraw] = useState<number>(0);
    const [moviesShowtimes, setMoviesShowtimes] = useState<any>({});
    const [moviesShowtimesDraw, setMoviesShowtimesDraw] = useState<number>(0);
    const [showtimeSummaryDraw, setShowtimeSummaryDraw] = useState<number>(0);
    const [seatSummary, setSeatSummary] = useState<any>({});
    const [seatSummaryDraw, setSeatSummaryDraw] = useState<number>(0);
    const [revenueSummary, setRevenueSummary] = useState<any>([]);
    const [revenueSummaryDraw, setRevenueSummaryDraw] = useState<number>(0);
    const [revenueBySeatType, setRevenueBySeatType] = useState<any[]>();
    const [revenueBySeatTypeDraw, setRevenueBySeatTypeDraw] = useState<number>(0);
    const [revenueByChain, setRevenueByChain] = useState<any[]>();
    const [revenueByChainDraw, setRevenueByChainDraw] = useState<number>(0);
    const [revenueByCinema, setRevenueByCinema] = useState<any[]>();
    const [revenueByCinemaDraw, setRevenueByCinemaDraw] = useState<number>(0);
    const [revenueByMovie, setRevenueByMovie] = useState<any[]>();
    const [revenueByMovieDraw, setRevenueByMovieDraw] = useState<number>(0);
    const [revenueByPLF, setRevenueByPLF] = useState<any[]>();
    const [revenueByPLFDraw, setRevenueByPLFDraw] = useState<number>(0);
    const [showtimeByDay, setShowtimeByDay] = useState<any[]>([]);
    const [showtimeByDayDraw, setShowtimeByDayDraw] = useState<number>(0);
    const [showtimeByChain, setShowtimeByChain] = useState<any[]>([]);
    const [showtimeByChainDraw, setShowtimeByChainDraw] = useState<number>(0);
    const [showtimeByCinema, setShowtimeByCinema] = useState<any[]>([]);
    const [showtimeByCinemaDraw, setShowtimeByCinemaDraw] = useState<number>(0);
    const [showtimeByMovie, setShowtimeByMovie] = useState<any[]>([]);
    const [showtimeByMovieDraw, setShowtimeByMovieDraw] = useState<number>(0);
    const [showtimeByHour, setShowtimeByHour] = useState<any[]>([]);
    const [showtimeByHourDraw, setShowtimeByHourDraw] = useState<number>(0);
    const [showtimeByPLF, setShowtimeByPLF] = useState<any[]>([]);
    const [showtimeByPLFDraw, setShowtimeByPLFDraw] = useState<number>(0);
    const [occupancyByDay, setOccupancyByDay] = useState<any[]>([]);
    const [occupancyByDayDraw, setOccupancyByDayDraw] = useState<number>(0);
    const [seatByDay, setSeatByDay] = useState<any[]>([]);
    const [seatByDayDraw, setSeatByDayDraw] = useState<number>(0);
    const [seatByChain, setSeatByChain] = useState<any[]>([]);
    const [seatByChainDraw, setSeatByChainDraw] = useState<number>(0);
    const [seatByCinema, setSeatByCinema] = useState<any[]>([]);
    const [seatByCinemaDraw, setSeatByCinemaDraw] = useState<number>(0);
    const [seatByMovie, setSeatByMovie] = useState<any[]>([]);
    const [seatByMovieDraw, setSeatByMovieDraw] = useState<number>(0);
    const [seatByStatus, setSeatByStatus] = useState<any[]>([]);
    const [seatByStatusDraw, setSeatByStatusDraw] = useState<number>(0);
    const [seatByPLF, setSeatByPLF] = useState<any[]>([]);
    const [seatByPLFDraw, setSeatByPLFDraw] = useState<number>(0);
    const [showtimes, setShowtimes] = useState<any[]>([]);
    const [showtimesDraw, setShowtimesDraw] = useState<number>(0);
    const [showtimesPage, setShowtimesPage] = useState<number>(1);
    const [showtimesPerPage, setShowtimesPerPage] = useState<number>(15);
    const [seatByCinemaLocation, setSeatByCinemaLocation] = useState<SeatByCinemaLocation[]>([]);
    const [seatByCinemaLocationDraw, setSeatByCinemaLocationDraw] = useState<number>(0);
    const [leaderboardDataByChain, setLeaderboardDataByChain] = useState<any[]>([]);
    const [leaderboardDataByChainDraw, setLeaderboardDataByChainDraw] = useState<number>(0);
    const [leaderboardDataByCinema, setLeaderboardDataByCinema] = useState<any[]>([]);
    const [leaderboardDataByCinemaDraw, setLeaderboardDataByCinemaDraw] = useState<number>(0);
    const [leaderboardDataByMovie, setLeaderboardDataByMovie] = useState<any[]>([]);
    const [leaderboardDataByMovieDraw, setLeaderboardDataByMovieDraw] = useState<number>(0);
    const [leaderboardDataByCountry, setLeaderboardDataByCountry] = useState<any[]>([]);
    const [leaderboardDataByCountryDraw, setLeaderboardDataByCountryDraw] = useState<number>(0);
    const [leaderboardDataByState, setLeaderboardDataByState] = useState<any[]>([]);
    const [leaderboardDataByStateDraw, setLeaderboardDataByStateDraw] = useState<number>(0);
    const [leaderboardDataByCity, setLeaderboardDataByCity] = useState<any[]>([]);
    const [leaderboardDataByCityDraw, setLeaderboardDataByCityDraw] = useState<number>(0);
    const [apiCountState, setApiCountState] = useState(0);

    const buildFilterParam = (exclude?: string, extraFilters: any = {}) => {
        return {
            ...(exclude === "movie_ids" ? {} : {movie_ids: selectedMovieIds}),
            ...(exclude === "chain_ids" ? {} : {chain_ids: selectedChainIds}),
            ...(exclude === "cinema_ids" ? {} : {cinema_ids: selectedCinemaIds}),
            ...(exclude === "screen_ids" ? {} : {screen_ids: selectedScreenIds}),
            ...(exclude === "cinema_countries" ? {} : {cinema_countries: selectedCinemaCountries}),
            ...(exclude === "cinema_states" ? {} : {cinema_states: selectedCinemaStates}),
            ...(exclude === "cinema_cities" ? {} : {cinema_cities: selectedCinemaCities}),
            ...(exclude === "attributes" ? {} : {attributes: selectedAttributes}),
            showtime_from: showtimeDateRange?.[0] ? momentCore(showtimeDateRange?.[0]).format("YYYY-MM-DD") : undefined,
            showtime_to: showtimeDateRange?.[1] ? momentCore(showtimeDateRange?.[1]).format("YYYY-MM-DD") : undefined,
            showtime_is_canceled: showtimeIsCanceled,
            cache: false,
            limit: 1000,
            ...(extraFilters?.kpi_type === KPI_TYPE.pre_sales ? {
                occupancy_from: occupancyDateRange?.[0] ? momentCore(occupancyDateRange?.[0]).format("YYYY-MM-DD") : undefined,
                occupancy_to: occupancyDateRange?.[1] ? momentCore(occupancyDateRange?.[1]).format("YYYY-MM-DD") : undefined,
            } : {}),
            ...extraFilters
        };
    };
    const fetchFromAPI = (endpoint: string, excludeParam: string, cb: Function, extraFilters = {}, _apiHitCount: number, cbDraw?: Function, updateApiCountState?: boolean) => {
        (function tryFetch(retry: number = 1) {
            axios.get(`${BASE_URL}${endpoint}`, {
                withCredentials: false,
                headers: {
                    ...commonHeaders
                },
                signal: controller.signal,
                timeout: 5 * 60 * 1000,
                params: buildFilterParam(excludeParam, extraFilters)
            })
                .then(function ({data}) {
                    if ((_apiHitCount === 0 || _apiHitCount === apiHitCount)) {
                        cb(processNumbers(data));
                        if (cbDraw) {
                            cbDraw(apiHitCount);
                        }
                        if (updateApiCountState) {
                            apiCount += 1;
                            setApiCountState(apiCount);
                        }
                    }
                })
                .catch(function (error) {
                    if (!axios.isCancel(error)) {
                        console.error(error);
                        /*
                        if (retry <= 3) {
                          setTimeout(function() {
                              tryFetch(retry++);
                            }, _.random(1000, 10000)
                          );
                        }
                         */
                    }
                });
        })();
    };

    const fetchAllData = () => {
        apiCount = 0;
        setApiCountState(0);
        apiHitCount += 1;
        setApiHitCountState(apiHitCount);
        controller = new AbortController();
        fetchFromAPI("/dashboard/movies-playing-in-chains", "", setMoviesPlayingInChains, {}, apiHitCount, setMoviesPlayingInChainsDraw, true);
        fetchFromAPI("/dashboard/movies-playing-in-cinemas", "", setMoviesPlayingInCinemas, {}, apiHitCount, setMoviesPlayingInCinemasDraw, true);
        fetchFromAPI("/dashboard/movies-playing-in-screens", "", setMoviesPlayingInScreens, {}, apiHitCount, setMoviesPlayingInScreensDraw, true);
        fetchFromAPI("/dashboard/movies-showtimes", "", setMoviesShowtimes, {}, apiHitCount, setMoviesShowtimesDraw, true);
        fetchFromAPI("/dashboard/leaderboard-data-by-chain", "", setLeaderboardDataByChain, {kpi_type: kpiType}, apiHitCount, setLeaderboardDataByChainDraw, true);
        fetchFromAPI("/dashboard/leaderboard-data-by-cinema", "", setLeaderboardDataByCinema, {kpi_type: kpiType}, apiHitCount, setLeaderboardDataByCinemaDraw, true);
        fetchFromAPI("/dashboard/leaderboard-data-by-movie", "", setLeaderboardDataByMovie, {kpi_type: kpiType}, apiHitCount, setLeaderboardDataByMovieDraw, true);
        fetchFromAPI("/dashboard/leaderboard-data-by-country", "", setLeaderboardDataByCountry, {kpi_type: kpiType}, apiHitCount, setLeaderboardDataByCountryDraw, true);
        fetchFromAPI("/dashboard/leaderboard-data-by-state", "", setLeaderboardDataByState, {kpi_type: kpiType}, apiHitCount, setLeaderboardDataByStateDraw, true);
        fetchFromAPI("/dashboard/leaderboard-data-by-city", "", setLeaderboardDataByCity, {kpi_type: kpiType}, apiHitCount, setLeaderboardDataByCityDraw, true);
        fetchFromAPI("/dashboard/showtime-summary", "", setShowtimeSummary, {}, apiHitCount, setShowtimeSummaryDraw, true);
        fetchFromAPI("/dashboard/seat-summary", "", setSeatSummary, {}, apiHitCount, setSeatSummaryDraw, true);
        fetchFromAPI("/dashboard/chains", "chain_ids", setChains, {}, apiHitCount, undefined, true);
        fetchFromAPI("/dashboard/cinemas", "cinema_ids", setCinemas, {}, apiHitCount, undefined, true);
        fetchFromAPI("/dashboard/screens", "screen_ids", setScreens, {}, apiHitCount, undefined, true);
        fetchFromAPI("/dashboard/cinema-countries", "cinema_countries", setCinemaCountries, {}, apiHitCount, undefined, true);
        fetchFromAPI("/dashboard/cinema-states", "cinema_states", setCinemaStates, {}, apiHitCount, undefined, true);
        fetchFromAPI("/dashboard/cinema-cities", "cinema_cities", setCinemaCities, {}, apiHitCount, undefined, true);
        fetchFromAPI("/dashboard/movies", "movie_ids", setMovies, {}, apiHitCount, undefined, true);
        fetchFromAPI("/dashboard/plfs", "attributes", setAttributes, {}, apiHitCount, undefined, true);
        fetchFromAPI("/dashboard/revenue-summary", "", setRevenueSummary, {}, apiHitCount, setRevenueSummaryDraw, true);
        fetchFromAPI("/dashboard/showtime-by-day", "", setShowtimeByDay, {}, apiHitCount, setShowtimeByDayDraw, true);
        //fetchFromAPI("/dashboard/showtime-by-chain", "", setShowtimeByChain, {}, apiHitCount, setShowtimeByChainDraw, true);
        //fetchFromAPI("/dashboard/showtime-by-cinema", "", setShowtimeByCinema, {}, apiHitCount, setShowtimeByCinemaDraw, true);
        //fetchFromAPI("/dashboard/showtime-by-movie", "", setShowtimeByMovie, {}, apiHitCount, setShowtimeByMovieDraw, true);
        fetchFromAPI("/dashboard/showtime-by-plf", "", setShowtimeByPLF, {}, apiHitCount, setShowtimeByPLFDraw, true);
        //fetchFromAPI("/dashboard/revenue-by-seat-type", "", setRevenueBySeatType, {}, apiHitCount, setRevenueBySeatTypeDraw, true);
        //fetchFromAPI("/dashboard/revenue-by-chain", "", setRevenueByChain, {}, apiHitCount, setRevenueByChainDraw, true);
        fetchFromAPI("/dashboard/revenue-by-cinema", "", setRevenueByCinema, {}, apiHitCount, setRevenueByCinemaDraw, true);
        fetchFromAPI("/dashboard/revenue-by-movie", "", setRevenueByMovie, {}, apiHitCount, setRevenueByMovieDraw, true);
        fetchFromAPI("/dashboard/revenue-by-plf", "", setRevenueByPLF, {}, apiHitCount, setRevenueByPLFDraw, true);
        fetchFromAPI("/dashboard/occupancy-by-day", "", setOccupancyByDay, {}, apiHitCount, setOccupancyByDayDraw, true);
        fetchFromAPI("/dashboard/seat-by-day", "", setSeatByDay, {}, apiHitCount, setSeatByDayDraw, true);
        //fetchFromAPI("/dashboard/seat-by-status", "", setSeatByStatus, {}, apiHitCount, setSeatByStatusDraw, true);
        //fetchFromAPI("/dashboard/seat-by-chain", "", setSeatByChain, {}, apiHitCount, setSeatByChainDraw, true);
        //fetchFromAPI("/dashboard/seat-by-cinema", "", setSeatByCinema, {}, apiHitCount, setSeatByCinemaDraw, true);
        //fetchFromAPI("/dashboard/seat-by-movie", "", setSeatByMovie, {}, apiHitCount, setSeatByMovieDraw, true);
        fetchFromAPI("/dashboard/seat-by-plf", "", setSeatByPLF, {}, apiHitCount, setSeatByPLFDraw, true);
        fetchFromAPI("/dashboard/seat-by-cinema-location", "", setSeatByCinemaLocation, {}, apiHitCount, setSeatByCinemaLocationDraw, true);
        fetchFromAPI("/dashboard/showtime-by-hour", "", setShowtimeByHour, {}, apiHitCount, setShowtimeByHourDraw, true);//slow
        //fetchFromAPI("/dashboard/showtimes", "", setShowtimes, {page: showtimesPage, limit: showtimesPerPage}, apiHitCount, setShowtimesDraw, true);
        setShowtimesPage(1);
    };

    useEffect(() => {
        //controller = new AbortController();
        //fetchFromAPI("/dashboard/showtimes", "", setShowtimes, {
        //             page: showtimesPage,
        //             limit: showtimesPerPage
        //         }, apiHitCount, setShowtimesDraw, false);
    }, [showtimesPage, showtimesPerPage]);

    useEffect(() => {
        fetchAllData();
    }, [kpiType, selectedCinemaStates, selectedCinemaCities, selectedMovieIds, selectedChainIds, selectedCinemaIds, selectedScreenIds, selectedCinemaCountries, showtimeDateRange, showtimeIsCanceled, selectedAttributes, occupancyDateRange]);

    function processNumbers(inputData: any) {
        if (Array.isArray(inputData)) {
            inputData.forEach(item => processNumbers(item));
        } else if (typeof inputData === "object" && inputData !== null) {
            for (let key in inputData) {
                if (inputData.hasOwnProperty(key)) {
                    let value = inputData[key];
                    let parsedValue = Number(value);
                    if (!isNaN(parsedValue) && isFinite(value)) {
                        inputData[key] = parsedValue;
                    }
                }
            }
        }
        return inputData;
    }

    const displayNumber = (data: string | number) => {
        let numberFormat = "0a";
        if (Number(data) > 10000) {
            numberFormat = "0.0a";
        } else if (Number(data) > 1000) {
            numberFormat = "0.00a";
        }
        return numeral(data).format(numberFormat).toUpperCase();
    };

    const formatPieChartData = (labelKey: string, dataKey: string, data: any[], maxItems: number = 10) => {
        const sortedData = data?.sort((a: any, b: any) => {
            return Number(b?.[dataKey]) - Number(a?.[dataKey]);
        })?.slice(0, maxItems);

        return {
            labels: sortedData?.map(s => `${s?.[labelKey] || "Unknown"}`),
            data: sortedData?.map(s => Number(s?.[dataKey]))
        };
    };


    const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "red", "pink", "rgba(153, 102, 255, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(255, 159, 64, 1)",
        "#8AAF22",
        "#8871A0",
        "#3F9F9F",
        "rgba(255, 206, 86, 1)",
        "#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"
    ];

    const totalPages = Math.ceil(showtimeSummary?._total_showtimes / showtimesPerPage);

    function generatePageNumbers(count: number, current: number): (string | number)[] {
        const shownPages = 3;
        const result = [];
        if (current > count - shownPages) {
            result.push(count - 2, count - 1, count);
        } else {
            result.push(current, current + 1, current + 2, "...", count);
        }
        return result;
    }

    const pageNumbers = generatePageNumbers(totalPages, showtimesPage);

    interface LeaderBoardChart {
        chartTitle: string;
        dataVar: any[];
        drawVar: number;
    }

    const leaderBoardCharts: LeaderBoardChart[] = [
        {
            chartTitle: "Cinema Chain",
            dataVar: leaderboardDataByChain,
            drawVar: leaderboardDataByChainDraw,
        }, {
            chartTitle: "Cinema",
            dataVar: leaderboardDataByCinema,
            drawVar: leaderboardDataByCinemaDraw,
        }, {
            chartTitle: "Movie",
            dataVar: leaderboardDataByMovie,
            drawVar: leaderboardDataByMovieDraw,
        }
        , {
            chartTitle: "Country",
            dataVar: leaderboardDataByCountry,
            drawVar: leaderboardDataByCountryDraw,
        },
        {
            chartTitle: "State",
            dataVar: leaderboardDataByState,
            drawVar: leaderboardDataByStateDraw,
        },
        {
            chartTitle: "City",
            dataVar: leaderboardDataByCity,
            drawVar: leaderboardDataByCityDraw,
        }
    ]


    const isFilterSelected = !!selectedMovieIds?.length


    return (
        <>
            <LoadingBar
                color="#0072f0"
                progress={Math.round((apiCountState / totalApis) * 100)}
                onLoaderFinished={() => {
                    apiCount = 0;
                    setApiCountState(0);
                }}
                style={{height: "5px"}}
            />
            <Container fluid={true} className="px-lg-5 mt-5">
                <Row className={"mb-5"}>
                    <Col xs={12}>
                        <h2 className="chart-group-title">
                            Filters
                        </h2>
                    </Col>
                    <Col md={3} sm={6}>
                        <FormGroup>
                            <Label>Cinema Chain</Label>
                            <Select onChange={(value) => {
                                if (controller) {
                                    controller.abort("Parameter changed");
                                }
                                setSelectedChainIds((value as any)?.map((v: any) => v?.value) || []);
                            }}
                                    options={chains?.map((chain: any) => ({
                                        value: chain?.chain_id,
                                        label: `${chain?.chain_name || "Unknown"} (${chain?._total_showtimes})`
                                    })) || []}
                                    components={animatedComponents} isMulti
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3} sm={6}>
                        <FormGroup>
                            <Label>Cinema</Label>
                            <Select onChange={(value) => {
                                if (controller) {
                                    controller.abort("Parameter changed");
                                }
                                setSelectedCinemaIds((value as any)?.map((v: any) => v?.value) || []);
                            }}
                                    options={cinemas?.map((cinema: any) => ({
                                        value: cinema?.cinema_id,
                                        label: `${cinema?.cinema_name || "Unknown"} (${cinema?._total_showtimes})`
                                    })) || []}
                                    components={animatedComponents} isMulti/>
                        </FormGroup>
                    </Col>
                    <Col md={3} sm={6}>
                        <FormGroup>
                            <Label>Auditorium</Label>
                            <Select onChange={(value) => {
                                if (controller) {
                                    controller.abort("Parameter changed");
                                }
                                setSelectedScreenIds((value as any)?.map((v: any) => v?.value) || []);
                            }}
                                    options={screens?.map((screen: any) => ({
                                        value: screen?.screen_id,
                                        label: `${screen?.screen_name} (${screen?._total_showtimes})`
                                    })) || []}
                                    components={animatedComponents} isMulti/>
                        </FormGroup>
                    </Col>
                    <Col md={3} sm={6}>
                        <FormGroup>
                            <Label>Country</Label>
                            <Select onChange={(value) => {
                                if (controller) {
                                    controller.abort("Parameter changed");
                                }
                                setSelectedCinemaCountries((value as any)?.map((v: any) => v?.value) || []);
                            }} options={cinemaCountries?.map((cinemaCountry: any) => ({
                                value: cinemaCountry.cinema_country,
                                label: `${cinemaCountry.cinema_country || "Unknown"} (${cinemaCountry?._total_showtimes})`
                            })) || []}
                                    components={animatedComponents} isMulti/>
                        </FormGroup>
                    </Col>
                    <Col md={3} sm={6}>
                        <FormGroup>
                            <Label>Country State</Label>
                            <Select onChange={(value) => {
                                if (controller) {
                                    controller.abort("Parameter changed");
                                }
                                setSelectedCinemaStates((value as any)?.map((v: any) => v?.value) || []);
                            }} options={cinemaStates?.map((cinemaState: any) => ({
                                value: cinemaState.cinema_state,
                                label: `${cinemaState.cinema_state || "Unknown"} (${cinemaState?._total_showtimes})`
                            })) || []}
                                    components={animatedComponents} isMulti/>
                        </FormGroup>
                    </Col>
                    <Col md={3} sm={6}>
                        <FormGroup>
                            <Label>City</Label>
                            <Select onChange={(value) => {
                                if (controller) {
                                    controller.abort("Parameter changed");
                                }
                                setSelectedCinemaCities((value as any)?.map((v: any) => v?.value) || []);
                            }} options={cinemaCities?.map((cinemaCity: any) => ({
                                value: cinemaCity.cinema_city,
                                label: `${cinemaCity.cinema_city || "Unknown"} (${cinemaCity?._total_showtimes})`
                            })) || []}
                                    components={animatedComponents} isMulti/>
                        </FormGroup>
                    </Col>
                    <Col md={3} sm={6}>
                        <FormGroup>
                            <Label>Movie</Label>
                            <Select onChange={(value) => {
                                if (controller) {
                                    controller.abort("Parameter changed");
                                }
                                setSelectedMovieIds((value as any)?.map((v: any) => v?.value) || []);
                            }} options={movies?.map((movie: any) => ({
                                value: movie?.movie_id,
                                label: `${movie?.movie_title || "Unknown"} (${movie?._total_showtimes})`
                            })) || []}
                                    components={animatedComponents} isMulti/>
                        </FormGroup>
                    </Col>
                    <Col md={3} sm={6}>
                        <FormGroup>
                            <Label className="small">Premium Large Format (3D, IMAX, etc.)</Label>
                            <Select onChange={(value) => {
                                if (controller) {
                                    controller.abort("Parameter changed");
                                }
                                setSelectedAttributes((value as any)?.map((v: any) => v?.value) || []);
                            }} options={attributes?.map((attribute: any) => ({
                                value: attribute?._attribute,
                                label: `${attribute?._attribute || "Unknown"} (${attribute?._total_showtimes})`
                            })) || []}
                                    components={animatedComponents} isMulti/>
                        </FormGroup>
                    </Col>
                    <Col md={3} sm={6}>
                        <FormGroup>
                            <Label>Showtime Date Range</Label>
                            <DatePicker
                                onChange={(date: any) => {
                                    if (controller) {
                                        controller.abort("Parameter changed");
                                    }
                                    setShowtimeDateRange(date);
                                }}
                                isClearable
                                selectsRange={true}
                                startDate={showtimeDateRange?.[0]}
                                endDate={showtimeDateRange?.[1]}
                                className="form-control"
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3} sm={6}>
                        <FormGroup>
                            <Label>Showtime Status
                                <span id="showtimes_status" className={"info-box"}>?</span>
                                <UncontrolledTooltip style={{minWidth: "300px"}} placement="top"
                                                     target="showtimes_status">
                                    We consider showtimes as canceled when this information is provided by the cinema or
                                    when a showtime
                                    is removed from the cinema's website for more than 24 hours.
                                </UncontrolledTooltip>
                            </Label>
                            <Select
                                isMulti={false}
                                defaultValue={{label: "Active", value: false}}
                                onChange={(value) => {
                                    if (controller) {
                                        controller.abort("Parameter changed");
                                    }
                                    setShowtimeIsCanceled((value as any)?.value as boolean);

                                }} options={[{
                                value: true, label: "Canceled"
                            }, {
                                value: false, label: "Active"
                            }]}
                                components={animatedComponents}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <h2 className="chart-group-title">
                            High-Level Insights
                        </h2>
                    </Col>
                    <Col lg={3} md={4} sm={6}>
                        <div className={classNames("score-card-container", {
                            "outdated-value": showtimeSummaryDraw !== apiHitCountState
                        })} style={{
                            ...(isFilterSelected && moviesPlayingInChains?.chainsPlayingSelectedMoviesPercent > 0 ?
                                { background: `linear-gradient(to top, #f5f5f5 ${Number(numeral(moviesPlayingInChains?.chainsPlayingSelectedMoviesPercent).format("0"))}%, white ${Number(numeral(moviesPlayingInChains?.chainsPlayingSelectedMoviesPercent).format("0"))}%)`} : {}
                                )
                        }}
                             title={isFilterSelected ? `Playing in ${moviesPlayingInChains?.chainsPlayingSelectedMovies} chains out of ${moviesPlayingInChains?.totalChains} chains.` : ''}
                        >
                            <div className="score-card-title">Cinema Chains Show The Movie</div>
                            <div className="score-card-percent-wrapper">
                                <div className="score-card-value"> {displayNumber(showtimeSummary?._total_chains)}</div>
                                {/*isFilterSelected ? <div className={"pie-progress-bar-container"}
                                    title={`Playing in ${moviesPlayingInChains?.chainsPlayingSelectedMovies} chains out of ${moviesPlayingInChains?.totalChains} chains.`}>
                                    <PieProgressBar
                                        percentage={Number(numeral(moviesPlayingInChains?.chainsPlayingSelectedMoviesPercent).format("0"))}
                                        size={90}/></div> : null*/}
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={4} sm={6}>
                        <div className={classNames("score-card-container", {
                            "outdated-value": showtimeSummaryDraw !== apiHitCountState
                        })}
                             style={{
                                 ...(isFilterSelected && moviesPlayingInCinemas?.cinemasPlayingSelectedMoviesPercent > 0 ?
                                         { background: `linear-gradient(to top, #f5f5f5 ${Number(numeral(moviesPlayingInCinemas?.cinemasPlayingSelectedMoviesPercent).format("0"))}%, white ${Number(numeral(moviesPlayingInCinemas?.cinemasPlayingSelectedMoviesPercent).format("0"))}%)`} : {}
                                 )
                             }}
                             title={isFilterSelected ? `Playing in ${moviesPlayingInCinemas?.cinemasPlayingSelectedMovies} cinemas out of ${moviesPlayingInCinemas?.totalCinemas} cinemas.` : ''}
                        >
                            <div className="score-card-title">Cinemas Show The Movie</div>
                            <div className="score-card-percent-wrapper">
                                <div className="score-card-value"> {displayNumber(showtimeSummary?._total_cinemas)}</div>
                                {/*isFilterSelected ? <div  className={"pie-progress-bar-container"}
                                    title={}>
                                    <PieProgressBar
                                        percentage={Number(numeral(moviesPlayingInCinemas?.cinemasPlayingSelectedMoviesPercent).format("0"))}
                                        size={90}/></div> : null*/}
                            </div>

                        </div>
                    </Col>
                    <Col lg={3} md={4} sm={6}>
                        <div className={classNames("score-card-container", {
                            "outdated-value": showtimeSummaryDraw !== apiHitCountState
                        })}
                             style={{
                                 ...(isFilterSelected && moviesShowtimes?.selectedMoviesShowtimesPercent > 0 ?
                                         { background: `linear-gradient(to top, #f5f5f5 ${Number(numeral(moviesShowtimes?.selectedMoviesShowtimesPercent).format("0"))}%, white ${Number(numeral(moviesShowtimes?.selectedMoviesShowtimesPercent).format("0"))}%)`} : {}
                                 )
                             }}
                             title={isFilterSelected ? `${displayNumber(moviesShowtimes?.selectedMoviesShowtimes)} showtimes out of ${displayNumber(moviesShowtimes?.totalShowtimes)} showtimes.` : ''}
                        >
                            <div className="score-card-title">Showtimes Published For The Movie</div>
                            <div className="score-card-percent-wrapper">
                                <div className="score-card-value"> {displayNumber(showtimeSummary?._total_showtimes)}</div>
                                {/*isFilterSelected ? <div  className={"pie-progress-bar-container"}
                                    title={`${displayNumber(moviesShowtimes?.selectedMoviesShowtimes)} showtimes out of ${displayNumber(moviesShowtimes?.totalShowtimes)} showtimes.`}>
                                    <PieProgressBar
                                        percentage={Number(numeral(moviesShowtimes?.selectedMoviesShowtimesPercent).format("0"))}
                                        size={90}/></div> : null*/}
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={4} sm={6}>
                        <div className={classNames("score-card-container", {
                            "outdated-value": showtimeSummaryDraw !== apiHitCountState
                        })}
                             style={{
                                 ...(isFilterSelected && moviesPlayingInScreens?.screensPlayingSelectedMoviesPercent > 0 ?
                                         { background: `linear-gradient(to top, #f5f5f5 ${Number(numeral(moviesPlayingInScreens?.screensPlayingSelectedMoviesPercent).format("0"))}%, white ${Number(numeral(moviesPlayingInScreens?.screensPlayingSelectedMoviesPercent).format("0"))}%)`} : {}
                                 )
                             }}
                             title={isFilterSelected ? `Playing in ${moviesPlayingInScreens?.screensPlayingSelectedMovies} screens out of ${moviesPlayingInScreens?.totalScreens} screens.` : ''}
                        >
                            <div className="score-card-title">Screens Used For The Movie</div>
                            <div className="score-card-percent-wrapper">
                                <div className="score-card-value"> {displayNumber(showtimeSummary?._total_screens)}</div>
                                {/*isFilterSelected ? <div  className={"pie-progress-bar-container"}
                                    title={`Playing in ${moviesPlayingInScreens?.screensPlayingSelectedMovies} screens out of ${moviesPlayingInScreens?.totalScreens} screens.`}>
                                    <PieProgressBar
                                        percentage={Number(numeral(moviesPlayingInScreens?.screensPlayingSelectedMoviesPercent).format("0"))}
                                        size={90}/></div> : null*/}
                            </div>
                        </div>
                    </Col>
                    {/*<Col lg={3} md={4} sm={6}>
                        <div className={classNames("score-card-container", {
                            "outdated-value": moviesPlayingInChainsDraw !== apiHitCountState
                        })}>
                            <div className="score-card-title">Cinema Chains Show The Movie %</div>
                            {isFilterSelected ? <div className="score-card-value"
                                                     title={`Playing in ${moviesPlayingInChains?.chainsPlayingSelectedMovies} chains out of ${moviesPlayingInChains?.totalChains} chains.`}> {displayNumber(moviesPlayingInChains?.chainsPlayingSelectedMoviesPercent)}%
                            </div> : <div className="score-card-value">0</div>}
                        </div>
                    </Col>
                    <Col lg={3} md={4} sm={6}>
                        <div className={classNames("score-card-container", {
                            "outdated-value": moviesPlayingInCinemasDraw !== apiHitCountState
                        })}>
                            <div className="score-card-title">Cinemas Show The Movie %</div>
                            {isFilterSelected ? <div className="score-card-value"
                                                     title={`Playing in ${moviesPlayingInCinemas?.cinemasPlayingSelectedMovies} cinemas out of ${moviesPlayingInCinemas?.totalCinemas} cinemas.`}> {displayNumber(moviesPlayingInCinemas?.cinemasPlayingSelectedMoviesPercent)}%
                            </div> : <div className="score-card-value">0</div>}
                        </div>
                    </Col>
                    <Col lg={3} md={4} sm={6}>
                        <div className={classNames("score-card-container", {
                            "outdated-value": moviesShowtimesDraw !== apiHitCountState
                        })}>
                            <div className="score-card-title">Showtimes Published For The Movie %</div>
                            {isFilterSelected ? <div className="score-card-value"
                                                     title={`${displayNumber(moviesShowtimes?.selectedMoviesShowtimes)} showtimes out of ${displayNumber(moviesShowtimes?.totalShowtimes)} showtimes.`}> {displayNumber(moviesShowtimes?.selectedMoviesShowtimesPercent)}%
                            </div> : <div className="score-card-value">0</div>}
                        </div>
                    </Col>
                    <Col lg={3} md={4} sm={6}>
                        <div className={classNames("score-card-container", {
                            "outdated-value": moviesPlayingInScreensDraw !== apiHitCountState
                        })}>
                            <div className="score-card-title">Screens Used For The Movie %</div>
                            {isFilterSelected ? <div className="score-card-value"
                                                     title={`Playing in ${moviesPlayingInScreens?.screensPlayingSelectedMovies} screens out of ${moviesPlayingInScreens?.totalScreens} screens.`}> {displayNumber(moviesPlayingInScreens?.screensPlayingSelectedMoviesPercent)}%
                            </div> : <div className="score-card-value">0</div>}
                        </div>
                    </Col>*/}
                    <Col lg={3} md={4} sm={6}>
                        <div className={classNames("score-card-container", {
                            "outdated-value": showtimeSummaryDraw !== apiHitCountState
                        })}>
                            <div className="score-card-title">Movies</div>
                            <div className="score-card-value"> {displayNumber(showtimeSummary?._total_movies)}</div>
                        </div>
                    </Col>
                    <Col lg={3} md={4} sm={6}>
                        <div className={classNames("score-card-container", {
                            "outdated-value": seatSummaryDraw !== apiHitCountState
                        })}>
                            <div className="score-card-title">All Available Seats</div>
                            <div className="score-card-value"> {displayNumber(seatSummary?._total_seats)}</div>
                        </div>
                    </Col>
                    <Col lg={3} md={4} sm={6}>
                        <div className={classNames("score-card-container", {
                            "outdated-value": seatSummaryDraw !== apiHitCountState
                        })}>
                            <div className="score-card-title">
                                Occupied Seats
                            </div>
                            <div
                                className={classNames("score-card-value")}>{displayNumber(seatSummary?._occupied_seats)}</div>
                        </div>
                    </Col>
                    <Col lg={3} md={4} sm={6}>
                        <div className={classNames("score-card-container", {
                            "outdated-value": seatSummaryDraw !== apiHitCountState
                        })}>
                            <div className="score-card-title">Not Occupied Seats</div>
                            <div className="score-card-value"> {displayNumber(seatSummary?._available_seats)}</div>
                        </div>
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col xs={12}>
                        <h2 className="chart-group-title">
                            Leader Board
                        </h2>
                    </Col>
                </Row>
                <div className="mt-3 mb-5">
                    <FormGroup row className="">
                        <Label md={2} sm={3} className="text-nowrap">KPI Filter
                            <span id="kpi_filter" className={"info-box"}>?</span>
                            <UncontrolledTooltip style={{minWidth: "300px"}} placement="top"
                                                 target="kpi_filter">
                                <ul className="m-3 p-3 text-start">
                                    <li>Released Showtimes: Counting all released showtimes</li>
                                    <li>Admissions: Count all booked seats that are occupied for showtimes in the
                                        filtered time range
                                    </li>
                                    <li>Pre-Sales: Count all booked seats that got booked in the filtered time range
                                    </li>
                                    <li>Booking Density: The Booking Density KPI explains the average number of booked
                                        seats per showtime, highlighting the occupancy of each showtime.
                                    </li>
                                    <li>Booking Rate: The Booking Rate explains the ratio of booked to unbooked seats.
                                        This KPI helps to compare the performance of released movies.
                                    </li>
                                    <li>Demand Pressure Index: A high Demand Pressure Index indicates an increased
                                        demand for showtimes that are currently limited, suggesting adding more
                                        showtimes to meet the audience demand.
                                    </li>
                                </ul>
                            </UncontrolledTooltip>
                        </Label>
                        <Col sm={3}>
                            <Select
                                isMulti={false}
                                defaultValue={{label: "Released Showtimes", value: "released_showtimes"}}
                                onChange={(value) => {
                                    if (controller) {
                                        controller.abort("Parameter changed");
                                    }
                                    setKpiType((value as any)?.value as string);
                                }} options={
                                [{
                                    value: KPI_TYPE.released_showtimes,
                                    label: "Released Showtimes"
                                }, {
                                    value: KPI_TYPE.admissions,
                                    label: "Admissions"
                                }, {
                                    value: KPI_TYPE.pre_sales,
                                    label: "Pre-Sales"
                                }, {
                                    value: KPI_TYPE.booking_density,
                                    label: "Booking Density"
                                }, {
                                    value: KPI_TYPE.booking_rate,
                                    label: "Booking Rate"
                                },
                                    {
                                        value: KPI_TYPE.demand_pressure_index,
                                        label: "Demand Pressure Index"
                                    },
                                ]}
                                components={animatedComponents}/>
                        </Col>
                    </FormGroup>
                    {
                        KPI_TYPE.pre_sales === kpiType ? <FormGroup row className="">
                            <Label md={2} sm={3}>Pre-Sales Range</Label>
                            <Col sm={3}>
                                <DatePicker
                                    onChange={(date: any) => {
                                        if (controller) {
                                            controller.abort("Parameter changed");
                                        }
                                        setOccupancyDateRange(date);
                                    }}
                                    isClearable
                                    selectsRange={true}
                                    startDate={occupancyDateRange?.[0]}
                                    endDate={occupancyDateRange?.[1]}
                                    className="form-control"
                                />
                            </Col>
                        </FormGroup> : null
                    }

                </div>
                <Row>
                    {leaderBoardCharts?.map(chart => {
                        return <Col md={4} key={chart.chartTitle}>
                            <h4 className="chart-title">{chart.chartTitle}</h4>
                            <div style={{height: 700}} className={classNames("chart-container", {
                                "outdated-value": chart.drawVar !== apiHitCountState
                            })}>
                                {/*<ResponsiveContainer width="100%" height="100%">
                                    <BarChart
                                        layout="vertical"
                                        data={chart.dataVar?.slice(0, 1000)}
                                    >
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <XAxis type="number" tickFormatter={(data) => {
                                            if (kpiType === KPI_TYPE.booking_rate) {
                                                return `${numeral(data).format("0")}%`;
                                            } else {
                                                return displayNumber(data)
                                            }
                                        }} domain={["dataMin", "dataMax"]}/>
                                        <Brush dataKey="name" height={25} stroke="#1589FF" startIndex={0} endIndex={7}
                                               tickFormatter={(data) => ''}/>
                                        <YAxis width={120} type="category" dataKey="name" name={chart.chartTitle}
                                               tickFormatter={(data) => data || "Unknown"}/>
                                        <Tooltip formatter={(data) => {
                                            if (kpiType === KPI_TYPE.released_showtimes) {
                                                return `${displayNumber(data as number)} Showtimes`;
                                            } else if (kpiType === KPI_TYPE.admissions || kpiType === KPI_TYPE.pre_sales) {
                                                return `${displayNumber(data as number)} Seats`;
                                            } else if (kpiType === KPI_TYPE.booking_density) {
                                                return `${numeral(data).format("0.0")} Seats`;
                                            } else if (kpiType === KPI_TYPE.booking_rate) {
                                                return `${numeral(data).format("0.0")}% Seats`;
                                            } else {
                                                return `${displayNumber(data as number)}`;
                                            }
                                        }}/>
                                        <Bar dataKey="time_of_day.Morning" name="Morning" fill="#1589FF"
                                             stackId="time_of_day"/>
                                        <Bar dataKey="time_of_day.Noon" name="Noon" fill="#1589FF"
                                             stackId="time_of_day"/>
                                        <Bar dataKey="time_of_day.Afternoon" name="Afternoon" fill="#1589FF"
                                             stackId="time_of_day"/>
                                        <Bar dataKey="time_of_day.Evening" name="Evening" fill="#1589FF"
                                             stackId="time_of_day"/>
                                        <Bar dataKey="time_of_day.Night" name="Night" fill="#1589FF"
                                             stackId="time_of_day"/>
                                        <Bar dataKey="time_of_day.Midnight" name="Midnight" fill="#1589FF"
                                             stackId="time_of_day"/>
                                    </BarChart>
                                </ResponsiveContainer>*/}

                                <ReactECharts style={{height: 700}} notMerge={true} option={{
                                    tooltip: {
                                        trigger: 'axis',
                                        axisPointer: {
                                            type: 'shadow',
                                            label: {
                                                show: true
                                            },
                                        },
                                        valueFormatter: function (data: number) {
                                            if (kpiType === KPI_TYPE.released_showtimes) {
                                                return `${displayNumber(data as number)} showtimes`;
                                            } else if (kpiType === KPI_TYPE.admissions || kpiType === KPI_TYPE.pre_sales) {
                                                return `${displayNumber(data as number)} seats`;
                                            } else if (kpiType === KPI_TYPE.booking_density) {
                                                return `${numeral(data).format("0.0")} seats`;
                                            } else if (kpiType === KPI_TYPE.booking_rate) {
                                                return `${numeral(data).format("0.0")}% seats`;
                                            } else {
                                                return `${displayNumber(data as number)}`;
                                            }
                                        },
                                    },
                                    toolbox: {
                                        show: true,
                                        feature: {
                                            dataView: {show: true, readOnly: false},
                                            magicType: {show: true, type: ['line', 'bar', 'stack']},
                                            saveAsImage: {show: true}
                                        }
                                    },
                                    calculable: true,
                                    grid: {
                                        top: '7%',
                                        left: '8%',
                                        right: '6%',
                                        containLabel: true
                                    },
                                    dataZoom: [
                                        {
                                            type: 'slider',
                                            show: true,
                                            height: 22,
                                        },
                                        {
                                            type: 'inside',
                                            orient: "vertical",
                                            yAxisIndex: 0,
                                            //xAxisIndex:0,
                                            startValue: 0,
                                            endValue: 15,
                                        },
                                        {
                                            type: 'slider',
                                            show: true,
                                            yAxisIndex: 0,
                                            //xAxisIndex:0,
                                            startValue: 0,
                                            endValue: 15,

                                            width: 22,
                                            height: '83%',
                                            showDataShadow: true,
                                            left: '0%',
                                            labelFormatter: function (value: any, valueStr: any) {
                                                return '';
                                            }
                                        }
                                    ],
                                    xAxis: {
                                        type: 'value',
                                        axisLabel: {
                                            formatter: function (data: number) {
                                                if (kpiType === KPI_TYPE.booking_rate) {
                                                    return `${numeral(data).format("0")}%`;
                                                } else {
                                                    return displayNumber(data)
                                                }
                                            }
                                        },

                                    },
                                    //legend: {
                                    //                                         data: ["Morning", "Noon","Afternoon","Evening","Night","Midnight"],
                                    //                                         itemGap: 5
                                    //                                     },
                                    yAxis: {
                                        inverse: true,
                                        type: 'category',
                                        data: chart.dataVar?.map(d => d?.name),
                                        axisLabel: {
                                            formatter: function (data: string) {
                                                return breakText(data);
                                            }
                                        },
                                    },
                                    color: [
                                        "#87CEFA",

                                        "#D3D3D3",

                                        "#5DA4EF",

                                        "#696969",

                                        "#3379E4",

                                        "#000000",
                                    ],
                                    colorertw: ["#1589FF", "#1589FF", '#3572EF', '#3572EF', '#050C9C', '#050C9C'],
                                    colofr2: [
                                        "#87CEFA",
                                        "#D8BFD8",
                                        '#5DA4EF',
                                        '#BA55D3',
                                        '#3379E4',
                                        '#9932CC'
                                    ],
                                    color11fd: [
                                        "#4A90E2", // Soft Blue
                                        "#7ED321", // Light Green
                                        "#F8E71C", // Warm Yellow
                                        "#FF6F61", // Coral
                                        "#BD10E0", // Lavender
                                        "#50E3C2"  // Turquoise
                                    ],
                                    colorf: [
                                        "#f46a9b",
                                        "rgb(255, 159, 64)",
                                        "#0088FE",
                                        "#00C49F",
                                        "#FFBB28",
                                        "#FF8042",
                                        "#8AAF22",
                                        "#8871A0",
                                        "#3F9F9F",
                                        "#ea5545",
                                        "#ef9b20",
                                        "#edbf33",
                                        "#ede15b",
                                        "#bdcf32",
                                        "#87bc45",
                                        "#27aeef",
                                        "#b33dc6"
                                    ],
                                    color1111: ["#1589FF", "#1589FF", "#4A90E2", "#50A2E8", "#5AB4EF", "#64C6F5"],
                                    color1fdg: ["#1267CC", "#1267CC", "#3D7BBF", "#4591D0", "#4DA7E0", "#55BCE6"],
                                    color4gfhd: ["#0F59B2", "#0F59B2", "#365E99", "#3E78A8", "#4692B7", "#4EABD7"],
                                    colorfdg: ["#1589FF", "#1589FF", "#365E99", "#3E78A8", "#4692B7", "#4EABD7"],
                                    colosdfr: ["#1267CC", "#1589FF", "#1051B3", "#0D4AA0", "#0A428D", "#073B7A"],
                                    col5or: ["#1589FF", "#1267CC", "#365E99", "#3D7BBF", "#4591D0", "#4DA7E0"],
                                    colorfg: ["#1589FF", "#0E6AC7", "#2C5A9B", "#3374A9", "#3A8EB7", "#41A8C5"],
                                    colorgfds: ["#1589FF", "#0E6AC7", "#FF8C00", "#FFD700", "#32CD32", "#8A2BE2"],
                                    series: ["Morning", "Noon", "Afternoon", "Evening", "Night", "Midnight"].map(t => {
                                        return {
                                            name: t,
                                            type: 'bar',
                                            stack: 'total',
                                            emphasis: {
                                                focus: 'series'
                                            },
                                            barGap: '0%',
                                            data: chart.dataVar?.map(d => {
                                                return d?.time_of_day?.[t] || 0
                                            }),
                                            //label: {
                                            //                                                 show: true,
                                            //                                                 position: 'inside',
                                            //                                                 emphasis: {
                                            //                                                     show: true,
                                            //                                                 },
                                            //                                                 formatter: function (data: any) {
                                            //                                                     if(!data.data){
                                            //                                                         return '';
                                            //                                                     }
                                            //                                                     if (kpiType === KPI_TYPE.booking_rate) {
                                            //                                                         return `${numeral(data.data).format("0")}%`;
                                            //                                                     } else {
                                            //                                                         return displayNumber(data.data)
                                            //                                                     }
                                            //                                                 }
                                            //                                             },
                                        }
                                    })
                                }}/>
                            </div>

                        </Col>
                    })}
                </Row>
                <Row>
                    <Col xs={12}>
                        <h2 className="chart-group-title">
                            Showtime and Occupancy Insights
                        </h2>
                    </Col>
                    <Col md={4}>
                        <h4 className="chart-title">Showtimes by date</h4>
                        <div className={classNames("chart-container", {
                            "outdated-value": showtimeByDayDraw !== apiHitCountState
                        })}>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    data={showtimeByDay}
                                >
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis
                                        dataKey="_showtime_date"
                                        name="Date"
                                        tickFormatter={_showtime_date => moment.utc(_showtime_date).format("D MMM, YYYY")}
                                    />
                                    <YAxis tickFormatter={(data) => displayNumber(data)}/>
                                    <Tooltip labelFormatter={(dt) => {
                                        return moment.utc(dt).format("D MMM, YYYY");
                                    }} formatter={(data) => {
                                        return displayNumber(data as number);
                                    }}/>
                                    <Legend/>
                                    <Brush dataKey="_showtime_date" height={25} stroke="#1589FF"/>
                                    <Bar dataKey="_total_showtimes" name="Showtimes" fill="#1589FF"/>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </Col>
                    <Col md={4}>
                        <h4 className="chart-title">Showtimes by hour<span id="showtimes_by_hour"
                                                                           className={"info-box"}>?</span>
                            <UncontrolledTooltip style={{minWidth: "300px"}} placement="top" target="showtimes_by_hour">
                                Below, you will find the accumulated showtimes organized by the hour of the day,
                                according to your
                                specified
                                filters. These showtimes represent the cumulative total for the upcoming 59 minutes. For
                                instance, the
                                showtimes at 1 PM include all the showtimes from 1:00 PM to 1:59 PM.
                            </UncontrolledTooltip>
                        </h4>
                        <div className={classNames("chart-container", {
                            "outdated-value": showtimeByHourDraw !== apiHitCountState
                        })}>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    data={showtimeByHour}
                                >
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis dataKey="_showtime_hour" name="Hour"/>
                                    <YAxis tickFormatter={(data) => displayNumber(data)}/>
                                    <Tooltip formatter={(data) => {
                                        return displayNumber(data as number);
                                    }}/>
                                    <Legend/>
                                    <Brush dataKey="_showtime_hour" height={25} stroke="#1589FF"/>
                                    <Bar dataKey="_total_showtimes" name="Showtimes" fill="#1589FF"/>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </Col>
                    <Col md={4}>
                        <h4 className="chart-title">Showtimes by premium large format <span
                            className="small text-muted">(3D, IMAX, etc.)</span>
                            <span id="showtimes_by_premium" className={"info-box"}>?</span>
                            <UncontrolledTooltip style={{minWidth: "300px"}} placement="top"
                                                 target="showtimes_by_premium">
                                We currently group all different kinds of experiences under this filter.
                                We plan to split the experiences into subcategories like Version → OMU, OV, etc.,
                                Seating → VIP, D-BOX, etc., PLF → 3D, IMAX, etc.
                                The subcategories will be available by individual filters where you can filter for
                                single versions,
                                seating types, etc.
                            </UncontrolledTooltip>
                        </h4>
                        <div className={classNames("chart-container", {
                            "outdated-value": showtimeByPLFDraw !== apiHitCountState
                        })}>
                            <ChartJSPie
                                width={"100%"}
                                redraw={true}
                                options={{
                                    maintainAspectRatio: false,
                                    plugins: {
                                        legend: {
                                            position: "right"
                                        }
                                    },
                                    animation: {
                                        duration: 0
                                    }
                                }}
                                data={{
                                    labels: formatPieChartData("_attribute", "_total_showtimes", showtimeByPLF).labels,
                                    datasets: [{
                                        label: "Showtimes",
                                        data: formatPieChartData("_attribute", "_total_showtimes", showtimeByPLF).data,
                                        backgroundColor: _.shuffle(colors)
                                    }]
                                }}/>
                        </div>
                    </Col>
                    <Col md={4}>
                        <h4 className="chart-title">Occupancy by date</h4>
                        <div className={classNames("chart-container", {
                            "outdated-value": seatByDayDraw !== apiHitCountState
                        })}>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    data={seatByDay}
                                >
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis
                                        dataKey="_showtime_date"
                                        name="Date"
                                        tickFormatter={_showtime_date => moment.utc(_showtime_date).format("D MMM, YYYY")}
                                    />
                                    <YAxis tickFormatter={(data) => displayNumber(data)}/>
                                    <Tooltip labelFormatter={(dt) => {
                                        return moment.utc(dt).format("D MMM, YYYY");
                                    }} formatter={(data) => {
                                        return displayNumber(data as number);
                                    }}/>
                                    <Legend/>
                                    <Brush dataKey="_showtime_date" height={25} stroke="#1589FF"/>
                                    <Bar stackId="a" dataKey="_occupied_seats" name="Occupied Seats" fill="#0000A0"/>
                                    {/*<Bar stackId="a" dataKey="_suspicious_occupied_seats" name="Suspicious Occupied Seats"
                             fill="#0000c7" />*/}
                                    <Bar stackId="a" dataKey="_available_seats" name="Availble Seats" fill="#1589FF"/>
                                    <Bar stackId="a" dataKey="_blocked_seats" name="Blocked Seats" fill="#EF5350"/>
                                    <Bar stackId="a" dataKey="_unknown_seats" name="Unknown Seats" fill="#FF9800"/>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </Col>
                    <Col md={4}>
                        <h4 className="chart-title">Occupancy by hour<span id="occupancy_by_hour"
                                                                           className={"info-box"}>?</span>
                            <UncontrolledTooltip style={{minWidth: "300px"}} placement="top" target="occupancy_by_hour">
                                Below, you will find the accumulated occupancy organized by the hour of the day,
                                according to your
                                specified
                                filters. The occupancy represents the cumulative total for the upcoming 59 minutes. For
                                instance, the
                                occupancy at 1 PM includes the total occupancy of all screenings from 1:00 PM to 1:59
                                PM.
                            </UncontrolledTooltip>
                        </h4>
                        <div className={classNames("chart-container", {
                            "outdated-value": showtimeSummaryDraw !== apiHitCountState
                        })}>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    data={showtimeByHour}
                                >
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis dataKey="_showtime_hour" name="Hour"/>
                                    <YAxis tickFormatter={(data) => displayNumber(data)}/>
                                    <Tooltip formatter={(data) => {
                                        return displayNumber(data as number);
                                    }}/>
                                    <Legend/>
                                    <Brush dataKey="_showtime_hour" height={25} stroke="#1589FF"
                                    />
                                    <Bar stackId="a" dataKey="_occupied_seats" name="Occupied Seats" fill="#0000A0"/>
                                    {/*<Bar stackId="a" dataKey="_suspicious_occupied_seats" name="Suspicious Occupied Seats"
                       fill="#0000c7" />*/}
                                    <Bar stackId="a" dataKey="_available_seats" name="Availble Seats" fill="#1589FF"/>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </Col>
                    <Col md={4}>
                        <h4 className="chart-title">Occupancy by premium large format <span
                            className="small text-muted">(3D, IMAX, etc.)</span>
                        </h4>
                        <div className={classNames("chart-container", {
                            "outdated-value": seatByPLFDraw !== apiHitCountState
                        })}>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    layout="vertical"
                                    data={seatByPLF}
                                >
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis
                                        tickFormatter={(data) => displayNumber(data)}
                                        type={"number"}
                                    />
                                    <YAxis
                                        width={150}
                                        type="category"
                                        dataKey="_attribute"
                                        name="PLF"
                                    />
                                    <Tooltip formatter={(data) => {
                                        return displayNumber(data as number);
                                    }}/>
                                    <Legend/>
                                    <Brush dataKey="_attribute" height={25} stroke="#1589FF" startIndex={0} endIndex={7}
                                           tickFormatter={(data) => ''}/>
                                    <Bar stackId="a" dataKey="_occupied_seats" name="Occupied Seats" fill="#0000A0"/>
                                    {/*<Bar stackId="a" dataKey="_suspicious_occupied_seats" name="Suspicious Occupied Seats"
                       fill="#0000c7" />*/}
                                    <Bar stackId="a" dataKey="_available_seats" name="Availble Seats" fill="#1589FF"/>
                                    <Bar stackId="a" dataKey="_blocked_seats" name="Blocked Seats" fill="#EF5350"/>
                                    <Bar stackId="a" dataKey="_unknown_seats" name="Unknown Seats" fill="#FF9800"/>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <h4 className="chart-title">Presales<span id="seat_booking_times"
                                                                            className={"info-box"}>?</span>
                            <UncontrolledTooltip style={{minWidth: "300px"}} placement="top"
                                                 target="seat_booking_times">
                                The chart below illustrates the timeframes during which seats were booked.
                            </UncontrolledTooltip>
                        </h4>
                        <div className={classNames("chart-container large", {
                            "outdated-value": occupancyByDayDraw !== apiHitCountState
                        })}>
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart
                                    data={occupancyByDay}
                                >
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis
                                        dataKey="_estimated_booking_date"
                                        name="Date"
                                        tickFormatter={_estimated_booking_date => moment.utc(_estimated_booking_date).format("D MMM, YYYY")}
                                    />
                                    <Tooltip labelFormatter={(dt) => {
                                        return moment.utc(dt).format("D MMM, YYYY");
                                    }} formatter={(data) => {
                                        return displayNumber(data as number);
                                    }}/>
                                    <YAxis tickFormatter={(data) => displayNumber(data)}/>
                                    <Legend/>
                                    <Brush dataKey="_estimated_booking_date" height={25} stroke="#1589FF"/>
                                    <Line type="monotone" dataKey="_occupied_seats" name="Occupied Seats"
                                          stroke="#0000c7"
                                          strokeWidth={3}/>
                                    {/*<Line type="monotone" dataKey="_suspicious_occupied_seats" name="Suspicious Occupied Seats"
                        stroke="#1589FF"
                        strokeWidth={3} />*/}
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div>
                            <h4 className="chart-title mb-4">Occupancy by Location</h4>
                            <div className={classNames("mb-5 pb-4", {
                                "outdated-value": seatByCinemaLocationDraw !== apiHitCountState
                            })}>
                                <OccupancyHeatmap data={seatByCinemaLocation}/>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col xs={12}>
                        <h2 className="chart-group-title">
                            Revenue Insights
                        </h2>
                    </Col>
                    <Col sm={6}>
                        <div
                            className={classNames("score-card-container small potential-card", {
                                "outdated-value": revenueSummaryDraw !== apiHitCountState
                            })}
                        >
                            <div className="score-card-title">Potential Revenue<span id="rotential_revenue"
                                                                                     className={"info-box"}>?</span>
                                <UncontrolledTooltip style={{minWidth: "300px"}} placement="top"
                                                     target="rotential_revenue">
                                    The potential revenue calculates the total count of available seats, based on your
                                    specified filter
                                    criteria, by the seat or ticket price. We prioritize using the actual seat price if
                                    available;
                                    otherwise,
                                    we use the first price listed in the cinema website's ticket price overview, which
                                    typically
                                    corresponds
                                    to the highest price. If you require customization in this pricing approach, we can
                                    accommodate your
                                    preferences upon request. Additionally, our API provides access to all available
                                    prices, allowing you
                                    to
                                    conduct your own pricing analysis.
                                </UncontrolledTooltip>
                            </div>
                            {revenueSummary?.map((o: any, index: number) => {
                                return <div key={index} className="score-card-value"><span
                                    className={"currency-label"}>{index > 0 ? "+" : ""}</span>{` `}{displayNumber(o?._potential_revenue)}
                                    {` `}<span
                                        className="currency-label">{o?.revenue_currency}</span></div>;
                            })}
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div
                            className={classNames("score-card-container small estimated-card", {
                                "outdated-value": revenueSummaryDraw !== apiHitCountState
                            })}
                        >
                            <div className="score-card-title">Estimated Revenue <span id="estimated_revenue"
                                                                                      className={"info-box"}>?</span>
                                <UncontrolledTooltip style={{minWidth: "300px"}} placement="top"
                                                     target="estimated_revenue">
                                    The estimated revenue calculates the total count of booked seats, based on your
                                    specified filter
                                    criteria,
                                    by the ticket price available on the cinema website. We prioritize using the actual
                                    seat price if
                                    available; otherwise, we use the first price listed in the cinema website's ticket
                                    price overview,
                                    which
                                    typically corresponds to the highest price. Custom pricing adjustments are available
                                    upon request.
                                    Additionally, our API provides access to all available prices, allowing you to
                                    conduct your own
                                    pricing
                                    analysis.
                                </UncontrolledTooltip>
                            </div>
                            {revenueSummary?.map((o: any, index: number) => {
                                return <div key={index} className="score-card-value">
                                    <span className={"currency-label"}>{index > 0 ? "+" : ""}</span>
                                    {` `} {displayNumber(o?._estimated_revenue)}
                                    {/*{` `}
                  {o?._suspicious_estimated_revenue > 0 ? <span
                    className="suspicious_rev">+{` `}{displayNumber(o?._suspicious_estimated_revenue)}</span> : ""}*/}
                                    {` `} <span className="currency-label">{o?.revenue_currency}</span>
                                </div>;
                            })}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <h4 className="chart-title">Revenue by cinema</h4>
                        <div className={classNames("chart-container huge", {
                            "outdated-value": revenueByCinemaDraw !== apiHitCountState
                        })}>
                            {/*<ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    data={revenueByCinema}
                                    layout="vertical"
                                >
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <YAxis
                                        width={150}
                                        type="category"
                                        dataKey="_cinema_name"
                                        name="Cinema"
                                    />
                                    <XAxis type={"number"} tickFormatter={(data) => displayNumber(data)}/>
                                    <Tooltip formatter={(data, name, item) => {
                                        return `${displayNumber(data as number)} ${item?.payload?.revenue_currency}`;
                                    }}/>
                                    <Legend/>
                                    <Brush dataKey="_cinema_name" height={25} stroke="#1589FF" startIndex={0}
                                           endIndex={7}
                                           tickFormatter={(data) => ''}/>
                                    <Bar stackId="a" dataKey="_estimated_revenue" name="Estimated Revenue"
                                         fill="#008000"/>
                                    <Bar stackId="b" dataKey="_potential_revenue" name="Potential Revenue"
                                         fill="#FFA500"/>
                                </BarChart>
                            </ResponsiveContainer>*/}
                            <ReactECharts style={{height: 750}} notMerge={true} option={{
                                tooltip: {
                                    trigger: 'axis',
                                    axisPointer: {
                                        type: 'shadow',
                                        label: {
                                            show: true
                                        },
                                    },
                                    valueFormatter: function (data: number) {
                                        return `${displayNumber(data as number)}`;
                                    },
                                },
                                toolbox: {
                                    show: true,
                                    feature: {
                                        dataView: {show: true, readOnly: false},
                                        magicType: {show: true, type: ['line', 'bar', 'stack']},
                                        saveAsImage: {show: true}
                                    }
                                },
                                calculable: true,
                                grid: {
                                    top: '7%',
                                    left: '8%',
                                    right: '6%',
                                    containLabel: true
                                },
                                dataZoom: [
                                    {
                                        type: 'slider',
                                        show: true,
                                        height: 22,
                                    },
                                    {
                                        type: 'inside',
                                        orient: "vertical",
                                        yAxisIndex: 0,
                                        //xAxisIndex:0,
                                        startValue: 0,
                                        endValue: 13,
                                    },
                                    {
                                        type: 'slider',
                                        show: true,
                                        yAxisIndex: 0,
                                        //xAxisIndex:0,
                                        startValue: 0,
                                        endValue: 13,

                                        width: 22,
                                        height: '83%',
                                        showDataShadow: true,
                                        left: '0%',
                                        labelFormatter: function (value: any, valueStr: any) {
                                            return '';
                                        }
                                    }
                                ],
                                xAxis: {
                                    type: 'value',
                                    axisLabel: {
                                        formatter: function (data: number) {
                                            return `${displayNumber(data as number)}`;
                                        }
                                    },

                                },
                                yAxis: {
                                    inverse: true,
                                    type: 'category',
                                    data: revenueByCinema?.map(d => d?._cinema_name),
                                    axisLabel: {
                                        formatter: function (data: string) {
                                            return breakText(data);
                                        }
                                    },
                                },

                                color: ["#008000", "#FFA500"],
                                legend: {
                                    data: ["Estimated Revenue", "Potential Revenue"],
                                    itemGap: 5
                                },
                                series: [
                                    {
                                        name: "Estimated Revenue",
                                        type: 'bar',
                                        //stack: 'total',
                                        emphasis: {
                                            focus: 'series'
                                        },
                                        barGap: '0%',
                                        data: revenueByCinema?.map(d => {
                                            return d?._estimated_revenue || 0
                                        }),
                                    },
                                    {
                                        name: "Potential Revenue",
                                        type: 'bar',
                                        //stack: 'total',
                                        emphasis: {
                                            focus: 'series'
                                        },
                                        barGap: '0%',
                                        data: revenueByCinema?.map(d => {
                                            return d?._potential_revenue || 0
                                        }),
                                    }

                                ]
                            }}/>
                        </div>
                    </Col>
                    <Col xs={4}>
                        <h4 className="chart-title">Revenue by movie</h4>
                        <div className={classNames("chart-container huge", {
                            "outdated-value": revenueByMovieDraw !== apiHitCountState
                        })}>
                            {/*<ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    layout="vertical"
                                    data={revenueByMovie}
                                >
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <YAxis
                                        width={150}
                                        type="category"
                                        dataKey="_movie_title"
                                        name="Movie"
                                    />
                                    <XAxis type={"number"} tickFormatter={(data) => displayNumber(data)}/>
                                    <Tooltip formatter={(data, name, item) => {
                                        return `${displayNumber(data as number)} ${item?.payload?.revenue_currency}`;
                                    }}/>
                                    <Legend/>
                                    <Brush dataKey="_movie_title" height={25} stroke="#1589FF" startIndex={0}
                                           endIndex={7}
                                           tickFormatter={(data) => ''}/>
                                    <Bar stackId="a" dataKey="_estimated_revenue" name="Estimated Revenue"
                                         fill="#008000"/>
                                    <Bar stackId="b" dataKey="_potential_revenue" name="Potential Revenue"
                                         fill="#FFA500"/>
                                </BarChart>
                            </ResponsiveContainer>*/}
                            <ReactECharts style={{height: 750}} notMerge={true} option={{
                                tooltip: {
                                    trigger: 'axis',
                                    axisPointer: {
                                        type: 'shadow',
                                        label: {
                                            show: true
                                        },
                                    },
                                    valueFormatter: function (data: number) {
                                        return `${displayNumber(data as number)}`;
                                    },
                                },
                                toolbox: {
                                    show: true,
                                    feature: {
                                        dataView: {show: true, readOnly: false},
                                        magicType: {show: true, type: ['line', 'bar', 'stack']},
                                        saveAsImage: {show: true}
                                    }
                                },
                                calculable: true,
                                grid: {
                                    top: '7%',
                                    left: '8%',
                                    right: '6%',
                                    containLabel: true
                                },
                                dataZoom: [
                                    {
                                        type: 'slider',
                                        show: true,
                                        height: 22,
                                    },
                                    {
                                        type: 'inside',
                                        orient: "vertical",
                                        yAxisIndex: 0,
                                        //xAxisIndex:0,
                                        startValue: 0,
                                        endValue: 13,
                                    },
                                    {
                                        type: 'slider',
                                        show: true,
                                        yAxisIndex: 0,
                                        //xAxisIndex:0,
                                        startValue: 0,
                                        endValue: 13,

                                        width: 22,
                                        height: '83%',
                                        showDataShadow: true,
                                        left: '0%',
                                        labelFormatter: function (value: any, valueStr: any) {
                                            return '';
                                        }
                                    }
                                ],
                                xAxis: {
                                    type: 'value',
                                    axisLabel: {
                                        formatter: function (data: number) {
                                            return `${displayNumber(data as number)}`;
                                        }
                                    },

                                },
                                yAxis: {
                                    inverse: true,
                                    type: 'category',
                                    data: revenueByMovie?.map(d => d?._movie_title),
                                    axisLabel: {
                                        formatter: function (data: string) {
                                            return breakText(data);
                                        }
                                    },
                                },

                                color: ["#008000", "#FFA500"],
                                legend: {
                                    data: ["Estimated Revenue", "Potential Revenue"],
                                    itemGap: 5
                                },
                                series: [
                                    {
                                        name: "Estimated Revenue",
                                        type: 'bar',
                                        //stack: 'total',
                                        emphasis: {
                                            focus: 'series'
                                        },
                                        barGap: '0%',
                                        data: revenueByMovie?.map(d => {
                                            return d?._estimated_revenue || 0
                                        }),
                                    },
                                    {
                                        name: "Potential Revenue",
                                        type: 'bar',
                                        //stack: 'total',
                                        emphasis: {
                                            focus: 'series'
                                        },
                                        barGap: '0%',
                                        data: revenueByMovie?.map(d => {
                                            return d?._potential_revenue || 0
                                        }),
                                    }

                                ]
                            }}/>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <h4 className="chart-title">Revenue by premium large format <span
                            className="small text-muted">(3D, IMAX, etc.)</span></h4>
                        <div className={classNames("chart-container huge", {
                            "outdated-value": revenueByPLFDraw !== apiHitCountState
                        })}>
                            {/*<ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    data={revenueByPLF}
                                    layout="vertical"
                                >
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <YAxis
                                        width={150}
                                        type="category"
                                        dataKey="_attribute"
                                        name="PLF"
                                    />
                                    <XAxis type={"number"} tickFormatter={(data) => displayNumber(data)}/>
                                    <Tooltip formatter={(data, name, item) => {
                                        return `${displayNumber(data as number)} ${item?.payload?.revenue_currency}`;
                                    }}/>
                                    <Legend/>
                                    <Brush dataKey="_attribute" height={25} stroke="#1589FF" startIndex={0} endIndex={7}
                                           tickFormatter={(data) => ''}/>
                                    <Bar stackId="a" dataKey="_estimated_revenue" name="Estimated Revenue"
                                         fill="#008000"/>
                                    <Bar stackId="b" dataKey="_potential_revenue" name="Potential Revenue"
                                         fill="#FFA500"/>
                                </BarChart>
                            </ResponsiveContainer>*/}
                            <ReactECharts style={{height: 750}} notMerge={true} option={{
                                tooltip: {
                                    trigger: 'axis',
                                    axisPointer: {
                                        type: 'shadow',
                                        label: {
                                            show: true
                                        },
                                    },
                                    valueFormatter: function (data: number) {
                                        return `${displayNumber(data as number)}`;
                                    },
                                },
                                toolbox: {
                                    show: true,
                                    feature: {
                                        dataView: {show: true, readOnly: false},
                                        magicType: {show: true, type: ['line', 'bar', 'stack']},
                                        saveAsImage: {show: true}
                                    }
                                },
                                calculable: true,
                                grid: {
                                    top: '7%',
                                    left: '8%',
                                    right: '6%',
                                    containLabel: true
                                },
                                dataZoom: [
                                    {
                                        type: 'slider',
                                        show: true,
                                        height: 22,
                                    },
                                    {
                                        type: 'inside',
                                        orient: "vertical",
                                        yAxisIndex: 0,
                                        //xAxisIndex:0,
                                        startValue: 0,
                                        endValue: 13,
                                    },
                                    {
                                        type: 'slider',
                                        show: true,
                                        yAxisIndex: 0,
                                        //xAxisIndex:0,
                                        startValue: 0,
                                        endValue: 13,

                                        width: 22,
                                        height: '83%',
                                        showDataShadow: true,
                                        left: '0%',
                                        labelFormatter: function (value: any, valueStr: any) {
                                            return '';
                                        }
                                    }
                                ],
                                xAxis: {
                                    type: 'value',
                                    axisLabel: {
                                        formatter: function (data: number) {
                                            return `${displayNumber(data as number)}`;
                                        }
                                    },

                                },
                                yAxis: {
                                    inverse: true,
                                    type: 'category',
                                    data: revenueByPLF?.map(d => d?._attribute),
                                    axisLabel: {
                                        formatter: function (data: string) {
                                            return breakText(data);
                                        }
                                    },
                                },

                                color: ["#008000", "#FFA500"],
legend: {
    data: ["Estimated Revenue", "Potential Revenue"],
    itemGap: 5
},
                                series: [
                                    {
                                        name: "Estimated Revenue",
                                        type: 'bar',
                                        //stack: 'total',
                                        emphasis: {
                                            focus: 'series'
                                        },
                                        barGap: '0%',
                                        data: revenueByPLF?.map(d => {
                                            return d?._estimated_revenue || 0
                                        }),
                                    },
                                    {
                                        name: "Potential Revenue",
                                        type: 'bar',
                                        //stack: 'total',
                                        emphasis: {
                                            focus: 'series'
                                        },
                                        barGap: '0%',
                                        data: revenueByPLF?.map(d => {
                                            return d?._potential_revenue || 0
                                        }),
                                    }

                                ]
                            }}/>
                        </div>
                    </Col>
                </Row>
                <ul className="mt-5">
                    <li>System timezone is UTC</li>
                </ul>
            </Container>
        </>
    );
}

export default DashboardV3;
