import "leaflet/dist/leaflet.css";
import { SeatByCinemaLocation } from "../interfaces/seatByCinemaLocation";
import { CSSProperties, useEffect, useState } from "react";
import { MapContainer, TileLayer } from 'react-leaflet';
import { HeatmapLayer } from "react-leaflet-heatmap-layer-v3";
import { LatLngLiteral } from "leaflet";
import React from "react";

interface OccupancyByLocationProps {
  data: SeatByCinemaLocation[];
}

const OccupancyHeatmap = ({ data }: OccupancyByLocationProps) => {
  const [centerPosition, setCenterPosition] = useState<LatLngLiteral>({ lat: 39.8871074, lng: -25.1207488 });
  const mapStyle: CSSProperties = { height: "450px" };
  const [points, setPoints] = useState<number[][]>([]);
  const [max, setMax] = useState<number>();
  const [grades, setGrades] = useState<{ value: string; color: string }[] | null>([]);

  const heatmapOptions = {
    radius: 25,
    blur: 15,
    maxZoom: 18,
    minOpacity: 0.8,
    maxOpacity: 1
  };

  const formatPosition = () => {
    return data.map((point: any) => [point.latitude, point.longitude,  point._occupied_seats]);
  }
  
  const calculatedGrades = (seats: number) => {
    if (typeof seats !== 'number' || seats < 0) {
      return null;
    }
  
    const grades: { value: string; color: string }[] = [];
    const milestones = [100, 75, 50, 25, 0];
  
    for (let i = 0; i < milestones.length; i++) {
      const key = milestones[i];
      const value = abbreviatedNumber(Math.round(key * (seats / 100)));
      const color = colors[i];
  
      grades.push({ value, color });
    }
  
    return grades;
  };

  const abbreviatedNumber = (value: number): string => {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + 'M';
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'K';
    } else {
      return value.toString();
    }
  };

  const colors = [
    "#8db0e4",
    "#6fa6cd",
    "#34d163",
    "#42ed47",
    "#28eb17",
    "#a1ed1c",
    "#c8f514",
    "#edca15",
    "#ee7b2d",
    "#fb332d",
    "#fb322e"
  ]

  const renderLegendValues = () => {
    return grades?.map((grade, index) => (
      <React.Fragment key={grade.value}>
        <span
          style={{
            position: 'absolute',
            top: `calc(10% + ${index * 20}% - ${index === grades.length -1 ? ' 8px' : ' 12px'})`,
            left: '40px'
          }}
        >
          {grade.value}
        </span>
      </React.Fragment>
    ));
  };

  useEffect(() => {
    const pointData = formatPosition();
    const maxOccupiedSeats = data.reduce((max, cinema) => Math.max(max, cinema._occupied_seats), 0);
    const _grades = calculatedGrades(maxOccupiedSeats);
    maxOccupiedSeats === 0 ? setGrades([]) : setGrades(_grades);
    setMax(maxOccupiedSeats);
    setPoints(pointData);
  }, [data]);
  return (
    <div>
      <MapContainer
          center={centerPosition} 
          zoom={2} 
          style={mapStyle} 
          maxZoom={18} 
        >
          {max != null && max > 0 && 
            <div className="heatmap-custom-legend">
              <div className="progress vertical">
                <div role="progressbar" style={{height: "100%"}} className="progress-bar"></div>
              </div>
              {renderLegendValues()}
              <span style={{position: 'absolute', bottom: '5px', left: '45px'}}>Unit: seats</span>
            </div>
          }
          <HeatmapLayer
            fitBoundsOnLoad
            fitBoundsOnUpdate
            points={points}
            longitudeExtractor={(point: any[]) => point[1]}
            latitudeExtractor={(point: any[]) => point[0]}
            key={Math.random() + Math.random()}
            intensityExtractor={(point: string[]) => parseFloat(point[2])}
            max={max}
            gradient={{
              0.1: '#8db0e4', 
              0.2: '#6fa6cd', 
              0.3: '#34d163', 
              0.4: '#42ed47', 
              0.5: '#28eb17', 
              0.6: '#a1ed1c', 
              0.7: '#c8f514', 
              0.8: '#edca15', 
              0.9: '#ee7b2d', 
              1: '#fb322e'
            }}
            {...heatmapOptions}
          />
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </MapContainer>
    </div>
  );
}

export default OccupancyHeatmap;
