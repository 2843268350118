
import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import axios from "axios";
import * as moment from "moment-timezone";
import momentCore from "moment";
import {
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
  Table,
  Pagination,
  PaginationItem, PaginationLink
} from "reactstrap";
import numeral from "numeral";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import { useParams, useSearchParams } from "react-router-dom";
import { ArcElement, Chart as ChartJS, Legend as ChartJSLegend, Tooltip as ChartJSTooltip } from "chart.js";
import { Pie as ChartJSPie } from "react-chartjs-2";
import _ from "lodash";
import classNames from "classnames";
import LoadingBar from "react-top-loading-bar";
import { SeatByCinemaLocation } from "../interfaces/seatByCinemaLocation";
import OccupancyHeatmap from "./OccupancyHeatmap";

ChartJS.register(ArcElement, ChartJSTooltip, ChartJSLegend);
const animatedComponents = makeAnimated();

let controller: AbortController;
let apiHitCount = 0;
let apiCount = 0;

function DashboardV2Dummy() {
  const totalApis = 17;
  let { apikey } = useParams();
  let BASE_URL = "";
  if (typeof window !== "undefined" && (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")) {
    BASE_URL = "https://api.screensights.io/api/v2";
  } else {
    BASE_URL = "https://api.screensights.io/api/v2";
  }
  const commonHeaders = {
    "X-Api-Key": apikey
  };

  const [apiHitCountState, setApiHitCountState] = useState<number>(0);
  const [showtimeIsCanceled, setShowtimeIsCanceled] = useState<boolean>(false);
  const [movies, setMovies] = useState<any>(null);
  const [selectedMovieIds, setSelectedMovieIds] = useState<number[]>([]);
  const [chains, setChains] = useState<any>(null);
  const [selectedChainIds, setSelectedChainIds] = useState<number[]>([]);
  const [selectedAttributes, setSelectedAttributes] = useState<number[]>([]);
  const [cinemas, setCinemas] = useState<any>(null);
  const [selectedScreenIds, setSelectedScreenIds] = useState<number[]>([]);
  const [selectedCinemaIds, setSelectedCinemaIds] = useState<number[]>([]);
  const [cinemaCountries, setCinemaCountries] = useState<string[]>([]);
  const [selectedCinemaCountries, setSelectedCinemaCountries] = useState<string[]>([]);
  const [showtimeDateRange, setShowtimeDateRange] = useState([null, null]);
  const [showtimeSummary, setShowtimeSummary] = useState<any>({});
  const [showtimeSummaryDraw, setShowtimeSummaryDraw] = useState<number>(0);
  const [seatSummary, setSeatSummary] = useState<any>({});
  const [seatSummaryDraw, setSeatSummaryDraw] = useState<number>(0);
  const [showtimeByDay, setShowtimeByDay] = useState<any[]>([]);
  const [showtimeByDayDraw, setShowtimeByDayDraw] = useState<number>(0);
  const [showtimeByChain, setShowtimeByChain] = useState<any[]>([]);
  const [showtimeByChainDraw, setShowtimeByChainDraw] = useState<number>(0);
  const [showtimeByCinema, setShowtimeByCinema] = useState<any[]>([]);
  const [showtimeByCinemaDraw, setShowtimeByCinemaDraw] = useState<number>(0);
  const [showtimeByMovie, setShowtimeByMovie] = useState<any[]>([]);
  const [showtimeByMovieDraw, setShowtimeByMovieDraw] = useState<number>(0);
  const [showtimeByHour, setShowtimeByHour] = useState<any[]>([]);
  const [showtimeByHourDraw, setShowtimeByHourDraw] = useState<number>(0);
  const [showtimeByPLF, setShowtimeByPLF] = useState<any[]>([]);
  const [showtimeByPLFDraw, setShowtimeByPLFDraw] = useState<number>(0);
  const [seatByDay, setSeatByDay] = useState<any[]>([]);
  const [seatByDayDraw, setSeatByDayDraw] = useState<number>(0);
  const [seatByChain, setSeatByChain] = useState<any[]>([]);
  const [seatByChainDraw, setSeatByChainDraw] = useState<number>(0);
  const [seatByCinema, setSeatByCinema] = useState<any[]>([]);
  const [seatByCinemaDraw, setSeatByCinemaDraw] = useState<number>(0);
  const [seatByMovie, setSeatByMovie] = useState<any[]>([]);
  const [seatByMovieDraw, setSeatByMovieDraw] = useState<number>(0);
  const [seatByPLF, setSeatByPLF] = useState<any[]>([]);
  const [seatByPLFDraw, setSeatByPLFDraw] = useState<number>(0);
  const [apiCountState, setApiCountState] = useState(0);

  const buildFilterParam = (exclude?: string, extraFilters: any = {}) => {
    return {
      ...(exclude === "movie_ids" ? {} : { movie_ids: selectedMovieIds }),
      ...(exclude === "chain_ids" ? {} : { chain_ids: selectedChainIds }),
      ...(exclude === "cinema_ids" ? {} : { cinema_ids: selectedCinemaIds }),
      ...(exclude === "screen_ids" ? {} : { screen_ids: selectedScreenIds }),
      ...(exclude === "cinema_countries" ? {} : { cinema_countries: selectedCinemaCountries }),
      ...(exclude === "attributes" ? {} : { attributes: selectedAttributes }),
      showtime_from: showtimeDateRange?.[0] ? momentCore(showtimeDateRange?.[0]).format("YYYY-MM-DD") : undefined,
      showtime_to: showtimeDateRange?.[1] ? momentCore(showtimeDateRange?.[1]).format("YYYY-MM-DD") : undefined,
      showtime_is_canceled: showtimeIsCanceled,
      cache: false,
      limit: 1000,
      ...extraFilters
    };
  };
  const fetchFromAPI = (endpoint: string, excludeParam: string, cb: Function, extraFilters = {}, _apiHitCount: number, cbDraw?: Function, updateApiCountState?: boolean) => {
    (function tryFetch(retry: number = 1) {
      axios.get(`${BASE_URL}${endpoint}`, {
        withCredentials: false,
        headers: {
          ...commonHeaders
        },
        signal: controller.signal,
        timeout: 5 * 60 * 1000,
        params: buildFilterParam(excludeParam, extraFilters)
      })
        .then(function({ data }) {
          if ((_apiHitCount === 0 || _apiHitCount === apiHitCount)) {
            cb(processNumbers(data));
            if (cbDraw) {
              cbDraw(apiHitCount);
            }
            if (updateApiCountState) {
              apiCount += 1;
              setApiCountState(apiCount);
            }
          }
        })
        .catch(function(error) {
          if (!axios.isCancel(error)) {
            console.error(error);
            /*
            if (retry <= 3) {
              setTimeout(function() {
                  tryFetch(retry++);
                }, _.random(1000, 10000)
              );
            }
             */
          }
        });
    })();
  };

  const fetchAllData = () => {
    apiCount = 0;
    setApiCountState(0);
    apiHitCount += 1;
    setApiHitCountState(apiHitCount);
    controller = new AbortController();
    fetchFromAPI("/dashboard/showtime-summary", "", setShowtimeSummary, {}, apiHitCount, setShowtimeSummaryDraw, true);
    fetchFromAPI("/dashboard/seat-summary", "", setSeatSummary, {}, apiHitCount, setSeatSummaryDraw, true);
    fetchFromAPI("/dashboard/chains", "chain_ids", setChains, {}, apiHitCount, undefined, true);
    fetchFromAPI("/dashboard/cinemas", "cinema_ids", setCinemas, {}, apiHitCount, undefined, true);
    fetchFromAPI("/dashboard/cinema-countries", "cinema_countries", setCinemaCountries, {}, apiHitCount, undefined, true);
    fetchFromAPI("/dashboard/movies", "movie_ids", setMovies, {}, apiHitCount, undefined, true);
    fetchFromAPI("/dashboard/showtime-by-day", "", setShowtimeByDay, {}, apiHitCount, setShowtimeByDayDraw, true);
    fetchFromAPI("/dashboard/showtime-by-chain", "", setShowtimeByChain, {}, apiHitCount, setShowtimeByChainDraw, true);
    fetchFromAPI("/dashboard/showtime-by-cinema", "", setShowtimeByCinema, {}, apiHitCount, setShowtimeByCinemaDraw, true);
    fetchFromAPI("/dashboard/showtime-by-movie", "", setShowtimeByMovie, {}, apiHitCount, setShowtimeByMovieDraw, true);
    fetchFromAPI("/dashboard/showtime-by-plf", "", setShowtimeByPLF, {}, apiHitCount, setShowtimeByPLFDraw, true);
    fetchFromAPI("/dashboard/seat-by-day", "", setSeatByDay, {}, apiHitCount, setSeatByDayDraw, true);
    fetchFromAPI("/dashboard/seat-by-chain", "", setSeatByChain, {}, apiHitCount, setSeatByChainDraw, true);
    fetchFromAPI("/dashboard/seat-by-cinema", "", setSeatByCinema, {}, apiHitCount, setSeatByCinemaDraw, true);
    fetchFromAPI("/dashboard/seat-by-movie", "", setSeatByMovie, {}, apiHitCount, setSeatByMovieDraw, true);
    fetchFromAPI("/dashboard/seat-by-plf", "", setSeatByPLF, {}, apiHitCount, setSeatByPLFDraw, true);
    fetchFromAPI("/dashboard/showtime-by-hour", "", setShowtimeByHour, {}, apiHitCount, setShowtimeByHourDraw, true);//slow
  };

  useEffect(() => {
    fetchAllData();
  }, [selectedMovieIds, selectedChainIds, selectedCinemaIds, selectedScreenIds, selectedCinemaCountries, showtimeDateRange, showtimeIsCanceled, selectedAttributes]);

  function processNumbers(inputData: any) {
    if (Array.isArray(inputData)) {
      inputData.forEach(item => processNumbers(item));
    } else if (typeof inputData === "object" && inputData !== null) {
      for (let key in inputData) {
        if (inputData.hasOwnProperty(key)) {
          let value = inputData[key];
          let parsedValue = Number(value);
          if (!isNaN(parsedValue) && isFinite(value)) {
            inputData[key] = parsedValue;
          }
        }
      }
    }
    return inputData;
  }

  const displayNumber = (data: string | number) => {
    let numberFormat = "0a";
    if (Number(data) > 10000) {
      numberFormat = "0.0a";
    } else if (Number(data) > 1000) {
      numberFormat = "0.00a";
    }
    return numeral(data).format(numberFormat).toUpperCase();
  };

  const formatPieChartData = (labelKey: string, dataKey: string, data: any[], maxItems: number = 10) => {
    const sortedData = data?.sort((a: any, b: any) => {
      return Number(b?.[dataKey]) - Number(a?.[dataKey]);
    })?.slice(0, maxItems);

    return {
      labels: sortedData?.map(s => `${s?.[labelKey] || "Unknown"}`), data: sortedData?.map(s => Number(s?.[dataKey]))
    };
  };


  const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "red", "pink", "rgba(153, 102, 255, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(255, 159, 64, 1)",
    "#8AAF22",
    "#8871A0",
    "#3F9F9F",
    "rgba(255, 206, 86, 1)",
    "#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"
  ];



  return (
    <>
      <LoadingBar
        color="#0072f0"
        progress={Math.round((apiCountState / totalApis) * 100)}
        onLoaderFinished={() => {
          apiCount = 0;
          setApiCountState(0);
        }}
        style={{ height: "5px" }}
      />
      <Container fluid={true} className="px-lg-5 mt-5">
        <Row className={"mb-5"}>
          <Col xs={12}>
            <h2 className="chart-group-title">
              Filters
            </h2>
          </Col>
          <Col md={3} sm={6}>
            <FormGroup>
              <Label>Country</Label>
              <Select onChange={(value) => {
                if (controller) {
                  controller.abort("Parameter changed");
                }
                setSelectedCinemaCountries((value as any)?.map((v: any) => v?.value) || []);
              }} options={cinemaCountries?.map((cinemaCountry: any) => ({
                value: cinemaCountry.cinema_country,
                label: `${cinemaCountry.cinema_country} (${cinemaCountry?._total_showtimes})`
              })) || []}
                      components={animatedComponents} isMulti />
            </FormGroup>
          </Col>
          <Col md={3} sm={6}>
            <FormGroup>
              <Label>Chain</Label>
              <Select onChange={(value) => {
                if (controller) {
                  controller.abort("Parameter changed");
                }
                setSelectedChainIds((value as any)?.map((v: any) => v?.value) || []);
              }}
                      options={chains?.map((chain: any) => ({
                        value: chain?.chain_id,
                        label: `${chain?.chain_name} (${chain?._total_showtimes})`
                      })) || []}
                      components={animatedComponents} isMulti />
            </FormGroup>
          </Col>
          <Col md={3} sm={6}>
            <FormGroup>
              <Label>Cinema</Label>
              <Select onChange={(value) => {
                if (controller) {
                  controller.abort("Parameter changed");
                }
                setSelectedCinemaIds((value as any)?.map((v: any) => v?.value) || []);
              }}
                      options={cinemas?.map((cinema: any) => ({
                        value: cinema?.cinema_id,
                        label: `${cinema?.cinema_name} (${cinema?._total_showtimes})`
                      })) || []}
                      components={animatedComponents} isMulti />
            </FormGroup>
          </Col>
          <Col md={3} sm={6}>
            <FormGroup>
              <Label>Movie</Label>
              <Select onChange={(value) => {
                if (controller) {
                  controller.abort("Parameter changed");
                }
                setSelectedMovieIds((value as any)?.map((v: any) => v?.value) || []);
              }} options={movies?.map((movie: any) => ({
                value: movie?.movie_id, label: `${movie?.movie_title} (${movie?._total_showtimes})`
              })) || []}
                      components={animatedComponents} isMulti />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h2 className="chart-group-title">
              High-Level Insights
            </h2>
          </Col>
          <Col lg={3} md={4} sm={6}>
            <div className={classNames("score-card-container", {
              "outdated-value": showtimeSummaryDraw !== apiHitCountState
            })}>
              <div className="score-card-title">Chains</div>
              <div className="score-card-value"> {displayNumber(showtimeSummary?._total_chains)}</div>
            </div>
          </Col>
          <Col lg={3} md={4} sm={6}>
            <div className={classNames("score-card-container", {
              "outdated-value": showtimeSummaryDraw !== apiHitCountState
            })}>
              <div className="score-card-title">Cinemas</div>
              <div className="score-card-value"> {displayNumber(showtimeSummary?._total_cinemas)}</div>
            </div>
          </Col>
          <Col lg={3} md={4} sm={6}>
            <div className={classNames("score-card-container", {
              "outdated-value": showtimeSummaryDraw !== apiHitCountState
            })}>
              <div className="score-card-title">Movies</div>
              <div className="score-card-value"> {displayNumber(showtimeSummary?._total_movies)}</div>
            </div>
          </Col>
          <Col lg={3} md={4} sm={6}>
            <div className={classNames("score-card-container", {
              "outdated-value": showtimeSummaryDraw !== apiHitCountState
            })}>
              <div className="score-card-title">Screens</div>
              <div className="score-card-value"> {displayNumber(showtimeSummary?._total_screens)}</div>
            </div>
          </Col>
          <Col lg={3} md={4} sm={6}>
            <div className={classNames("score-card-container", {
              "outdated-value": showtimeSummaryDraw !== apiHitCountState
            })}>
              <div className="score-card-title">Showtimes</div>
              <div className="score-card-value"> {displayNumber(showtimeSummary?._total_showtimes)}</div>
            </div>
          </Col>
          <Col lg={3} md={4} sm={6}>
            <div className={classNames("score-card-container", {
              "outdated-value": seatSummaryDraw !== apiHitCountState
            })}>
              <div className="score-card-title">Total Seats</div>
              <div className="score-card-value"> {displayNumber(seatSummary?._total_seats)}</div>
            </div>
          </Col>
          <Col lg={3} md={4} sm={6}>
            <div className={classNames("score-card-container", {
              "outdated-value": seatSummaryDraw !== apiHitCountState
            })}>
              <div className="score-card-title">Available Seats</div>
              <div className="score-card-value"> {displayNumber(seatSummary?._available_seats)}</div>
            </div>
          </Col>
          <Col lg={3} md={4} sm={6}>
            <div className={classNames("score-card-container", {
              "outdated-value": seatSummaryDraw !== apiHitCountState
            })}>
              <div className="score-card-title">
                Occupied Seats
              </div>
              <div className={classNames("score-card-value")}>{displayNumber(seatSummary?._occupied_seats)}</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h2 className="chart-group-title">
              Showtime Insights
            </h2>
          </Col>
          <Col md={4}>
            <h4 className="chart-title">Showtimes by date</h4>
            <div className={classNames("chart-container", {
              "outdated-value": showtimeByDayDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={showtimeByDay}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="_showtime_date"
                    name="Date"
                    tickFormatter={_showtime_date => moment.utc(_showtime_date).format("D MMM, YYYY")}
                  />
                  <YAxis tickFormatter={(data) => displayNumber(data)} />
                  <Tooltip labelFormatter={(dt) => {
                    return moment.utc(dt).format("D MMM, YYYY");
                  }} formatter={(data) => {
                    return displayNumber(data as number);
                  }} />
                  <Legend />
                  <Bar dataKey="_total_showtimes" name="Showtimes" fill="#1589FF" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col md={4}>
            <h4 className="chart-title">Showtimes by hour<span id="showtimes_by_hour" className={"info-box"}>?</span>
              <UncontrolledTooltip style={{ minWidth: "300px" }} placement="top" target="showtimes_by_hour">
                Below, you will find the accumulated showtimes organized by the hour of the day, according to your
                specified
                filters. These showtimes represent the cumulative total for the upcoming 59 minutes. For instance, the
                showtimes at 1 PM include all the showtimes from 1:00 PM to 1:59 PM.
              </UncontrolledTooltip>
            </h4>
            <div className={classNames("chart-container", {
              "outdated-value": showtimeByHourDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={showtimeByHour}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="_showtime_hour" name="Hour" />
                  <YAxis tickFormatter={(data) => displayNumber(data)} />
                  <Tooltip formatter={(data) => {
                    return displayNumber(data as number);
                  }} />
                  <Legend />
                  <Bar dataKey="_total_showtimes" name="Showtimes" fill="#1589FF" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col md={4}>
            <h4 className="chart-title">Showtimes by premium large format <span className="small text-muted">(3D, IMAX, etc.)</span>
              <span id="showtimes_by_premium" className={"info-box"}>?</span>
              <UncontrolledTooltip style={{ minWidth: "300px" }} placement="top" target="showtimes_by_premium">
                We currently group all different kinds of experiences under this filter.
                We plan to split the experiences into subcategories like Version → OMU, OV, etc.,
                Seating → VIP, D-BOX, etc., PLF → 3D, IMAX, etc.
                The subcategories will be available by individual filters where you can filter for single versions,
                seating types, etc.
              </UncontrolledTooltip>
            </h4>
            <div className={classNames("chart-container", {
              "outdated-value": showtimeByPLFDraw !== apiHitCountState
            })}>
              <ChartJSPie
                width={"100%"}
                redraw={true}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: "right"
                    }
                  },
                  animation: {
                    duration: 0
                  }
                }}
                data={{
                  labels: formatPieChartData("_attribute", "_total_showtimes", showtimeByPLF).labels, datasets: [{
                    label: "Showtimes",
                    data: formatPieChartData("_attribute", "_total_showtimes", showtimeByPLF).data,
                    backgroundColor: _.shuffle(colors)
                  }]
                }} />
            </div>
          </Col>
          <Col md={4}>
            <h4 className="chart-title">Showtimes by chain</h4>
            <div className={classNames("chart-container large", {
              "outdated-value": showtimeByChainDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  layout="vertical"
                  data={showtimeByChain}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" tickFormatter={(data) => displayNumber(data)} />
                  <YAxis width={120} type="category" dataKey="_chain_name" name="Chain" />
                  <Tooltip formatter={(data) => {
                    return displayNumber(data as number);
                  }} />
                  <Legend />
                  <Bar dataKey="_total_showtimes" name="Showtimes" fill="#1589FF" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col md={4}>
            <h4 className="chart-title">Showtimes by cinema</h4>
            <div className={classNames("chart-container large", {
              "outdated-value": showtimeByCinemaDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  layout="vertical"
                  data={showtimeByCinema}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    type="number"
                    tickFormatter={(data) => displayNumber(data)}
                  />
                  <YAxis
                    width={150}
                    type="category"
                    dataKey="_cinema_name"
                    name="Cinema"
                  />
                  <Tooltip formatter={(data) => {
                    return displayNumber(data as number);
                  }} />
                  <Legend />
                  <Bar dataKey="_total_showtimes" name="Showtimes" fill="#1589FF" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col md={4}>
            <h4 className="chart-title">Showtimes by movie</h4>
            <div className={classNames("chart-container large", {
              "outdated-value": showtimeByMovieDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={showtimeByMovie}
                  layout="vertical"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    type={"number"}
                    tickFormatter={(data) => displayNumber(data)}
                  />
                  <YAxis
                    width={120}
                    type="category"
                    dataKey="_movie_title"
                    name="Movie"
                  />
                  <Tooltip formatter={(data) => {
                    return displayNumber(data as number);
                  }} />
                  <Legend />
                  <Bar dataKey="_total_showtimes" name="Showtimes" fill="rgb(0, 114, 240)" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h2 className="chart-group-title">
              Occupancy Insights
            </h2>
          </Col>
          <Col md={4}>
            <h4 className="chart-title">Occupancy by date</h4>
            <div className={classNames("chart-container", {
              "outdated-value": seatByDayDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={seatByDay}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="_showtime_date"
                    name="Date"
                    tickFormatter={_showtime_date => moment.utc(_showtime_date).format("D MMM, YYYY")}
                  />
                  <YAxis tickFormatter={(data) => displayNumber(data)} />
                  <Tooltip labelFormatter={(dt) => {
                    return moment.utc(dt).format("D MMM, YYYY");
                  }} formatter={(data) => {
                    return displayNumber(data as number);
                  }} />
                  <Legend />
                  <Bar stackId="a" dataKey="_occupied_seats" name="Occupied Seats" fill="#0000A0" />
                  {/*<Bar stackId="a" dataKey="_suspicious_occupied_seats" name="Suspicious Occupied Seats"
                             fill="#0000c7" />*/}
                  <Bar stackId="a" dataKey="_available_seats" name="Availble Seats" fill="#1589FF" />
                  <Bar stackId="a" dataKey="_blocked_seats" name="Blocked Seats" fill="#EF5350" />
                  <Bar stackId="a" dataKey="_unknown_seats" name="Unknown Seats" fill="#FF9800" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col md={4}>
            <h4 className="chart-title">Occupancy by hour<span id="occupancy_by_hour" className={"info-box"}>?</span>
              <UncontrolledTooltip style={{ minWidth: "300px" }} placement="top" target="occupancy_by_hour">
                Below, you will find the accumulated occupancy organized by the hour of the day, according to your
                specified
                filters. The occupancy represents the cumulative total for the upcoming 59 minutes. For instance, the
                occupancy at 1 PM includes the total occupancy of all screenings from 1:00 PM to 1:59 PM.
              </UncontrolledTooltip>
            </h4>
            <div className={classNames("chart-container", {
              "outdated-value": showtimeSummaryDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={showtimeByHour}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="_showtime_hour" name="Hour" />
                  <YAxis tickFormatter={(data) => displayNumber(data)} />
                  <Tooltip formatter={(data) => {
                    return displayNumber(data as number);
                  }} />
                  <Legend />
                  <Bar stackId="a" dataKey="_occupied_seats" name="Occupied Seats" fill="#0000A0" />
                  {/*<Bar stackId="a" dataKey="_suspicious_occupied_seats" name="Suspicious Occupied Seats"
                       fill="#0000c7" />*/}
                  <Bar stackId="a" dataKey="_available_seats" name="Availble Seats" fill="#1589FF" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col md={4}>
            <h4 className="chart-title">Occupancy by premium large format <span className="small text-muted">(3D, IMAX, etc.)</span>
            </h4>
            <div className={classNames("chart-container", {
              "outdated-value": seatByPLFDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  layout="vertical"
                  data={seatByPLF}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    tickFormatter={(data) => displayNumber(data)}
                    type={"number"}
                  />
                  <YAxis
                    width={150}
                    type="category"
                    dataKey="_attribute"
                    name="PLF"
                  />
                  <Tooltip formatter={(data) => {
                    return displayNumber(data as number);
                  }} />
                  <Legend />
                  <Bar stackId="a" dataKey="_occupied_seats" name="Occupied Seats" fill="#0000A0" />
                  {/*<Bar stackId="a" dataKey="_suspicious_occupied_seats" name="Suspicious Occupied Seats"
                       fill="#0000c7" />*/}
                  <Bar stackId="a" dataKey="_available_seats" name="Availble Seats" fill="#1589FF" />
                  <Bar stackId="a" dataKey="_blocked_seats" name="Blocked Seats" fill="#EF5350" />
                  <Bar stackId="a" dataKey="_unknown_seats" name="Unknown Seats" fill="#FF9800" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col md={4}>
            <h4 className="chart-title">Occupancy by chain</h4>
            <div className={classNames("chart-container large", {
              "outdated-value": seatByChainDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  layout="vertical"
                  data={seatByChain}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" tickFormatter={(data) => displayNumber(data)} />
                  <YAxis width={120} type="category" dataKey="_chain_name" name="Chain" />
                  <Tooltip formatter={(data) => {
                    return displayNumber(data as number);
                  }} />
                  <Legend />
                  <Bar stackId="a" dataKey="_occupied_seats" name="Occupied Seats" fill="#0000A0" />
                  {/*<Bar stackId="a" dataKey="_suspicious_occupied_seats" name="Suspicious Occupied Seats"
                       fill="#0000c7" />*/}
                  <Bar stackId="a" dataKey="_available_seats" name="Availble Seats" fill="#1589FF" />
                  <Bar stackId="a" dataKey="_blocked_seats" name="Blocked Seats" fill="#EF5350" />
                  <Bar stackId="a" dataKey="_unknown_seats" name="Unknown Seats" fill="#FF9800" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col md={4}>
            <h4 className="chart-title">Occupancy by cinema</h4>
            <div className={classNames("chart-container large", {
              "outdated-value": seatByCinemaDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  layout="vertical"
                  data={seatByCinema}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    type="number"
                    tickFormatter={(data) => displayNumber(data)}
                  />
                  <YAxis
                    width={150}
                    type="category"
                    dataKey="_cinema_name"
                    name="Cinema"
                  />
                  <Tooltip formatter={(data) => {
                    return displayNumber(data as number);
                  }} />
                  <Legend />
                  <Bar stackId="a" dataKey="_occupied_seats" name="Occupied Seats" fill="#0000A0" />
                  {/*<Bar stackId="a" dataKey="_suspicious_occupied_seats" name="Suspicious Occupied Seats"
                       fill="#0000c7" />*/}
                  <Bar stackId="a" dataKey="_available_seats" name="Availble Seats" fill="#1589FF" />
                  <Bar stackId="a" dataKey="_blocked_seats" name="Blocked Seats" fill="#EF5350" />
                  <Bar stackId="a" dataKey="_unknown_seats" name="Unknown Seats" fill="#FF9800" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col md={4}>
            <h4 className="chart-title">Occupancy by movie</h4>
            <div className={classNames("chart-container large", {
              "outdated-value": seatByMovieDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={seatByMovie}
                  layout="vertical"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    type={"number"}
                    tickFormatter={(data) => displayNumber(data)}
                  />
                  <YAxis
                    width={120}
                    type="category"
                    dataKey="_movie_title"
                    name="Movie"
                  />
                  <Tooltip formatter={(data) => {
                    return displayNumber(data as number);
                  }} />
                  <Legend />
                  <Bar stackId="a" dataKey="_occupied_seats" name="Occupied Seats" fill="#0000A0" />
                  {/*<Bar stackId="a" dataKey="_suspicious_occupied_seats" name="Suspicious Occupied Seats"
                       fill="#0000c7" />*/}
                  <Bar stackId="a" dataKey="_available_seats" name="Availble Seats" fill="#1589FF" />
                  <Bar stackId="a" dataKey="_blocked_seats" name="Blocked Seats" fill="#EF5350" />
                  <Bar stackId="a" dataKey="_unknown_seats" name="Unknown Seats" fill="#FF9800" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
        </Row>

        <ul className="mt-5">
          <li>System timezone is UTC</li>
        </ul>
      </Container>
    </>
  );
}

export default DashboardV2Dummy;
