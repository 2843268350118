export const COLORS = [
  '#8e8231', '#0303fc', '#7703fc', '#a80000', '#6a00a8', '#82043d',
  '#e44600', '#a37344', '#1f6800', '#2c4d1f', '#fcba03', '#861d22',
  '#3edd9c', '#d91959', '#315519', '#4b371d', '#3d7666', '#d09d00',
  '#3f524b', '#8d0000', '#81cc0c', '#305702', '#92db14', '#63370c',
  '#29008b', '#5a7eb9', '#a099',   '#98de00', '#081925', '#b6a828',
  '#a2bbe6', '#d2b4b4', '#44eb94', '#211263', '#6dda03', '#97aceb',
  '#1b037b', '#5418c9', '#85ab69', '#1e0075', '#3655d5', '#ad720c',
  '#2c33cd', '#cd7fd3', '#2ea02d', '#0095cd', '#13000a', '#12bb',
  '#e300',   '#d9ab06', '#803200', '#e8a07c', '#e7ea94', '#8ad7a4',
  '#91d79e', '#15b35f', '#05db9f', '#c5b06a', '#0a00',   '#8114',
  '#46549e', '#251338', '#454b',   '#cd210e', '#df50a5', '#73d0e8',
  '#e94d36', '#0d0014', '#a69d26', '#41cfbb', '#5334d2', '#3a0d54',
  '#9b0b44', '#3b004d', '#2e56a3', '#0d6de0', '#a8c39b', '#6467ad',
  '#a6de5e', '#4ddf0c', '#5200cb', '#8a500e', '#7c00d8', '#240006',
  '#132cbe', '#82c400', '#d4306f', '#724d',   '#a7217b', '#94e16f',
  '#035d81', '#398845', '#237600', '#7b31',   '#004112', '#358ba2',
  '#d25eb2', '#c7d903', '#0f8078', '#aa3ccf'
]
