import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import './index.scss';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import CompareChart from './components/CompareChart';
import Dashboard_V2 from "./components/Dashboard_V2";
import CompareChart_V2 from "./components/CompareChart_V2";
import Dashboard_V2_Dummy from "./components/Dashboard_V2_Dummy";
import DashboardV3 from "./components/Dashboard_V3";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <App />,
  },
  {
    path: "/compare-chart/v2/:apikey",
    element:  <CompareChart_V2 />,
  },
  {
    path: "/compare-chart/v1/:apikey",
    element:  <CompareChart />,
  },
  {
    path: "/compare-chart/:apikey",
    element:  <CompareChart_V2 />,
  },
  {
    path: "v2/:apikey",
    element:  <Dashboard_V2 />,
  },
  {
    path: "v1/:apikey",
    element:  <Dashboard />,
  },
  {
    path: "/:apikey",
    element:  <Dashboard_V2 />,
  },
  {
    path: "/preview/:apikey",
    element:  <Dashboard_V2_Dummy />,
  },
  {
    path: "/v3/:apikey",
    element:  <DashboardV3 />,
  },
]);
root.render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
