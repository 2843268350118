import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import axios from "axios";
import * as moment from "moment-timezone";
import momentCore from "moment";
import {
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
  Table,
  Pagination,
  PaginationItem, PaginationLink
} from "reactstrap";
import numeral from "numeral";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import { useParams, useSearchParams } from "react-router-dom";
import { ArcElement, Chart as ChartJS, Legend as ChartJSLegend, Tooltip as ChartJSTooltip } from "chart.js";
import { Pie as ChartJSPie } from "react-chartjs-2";
import _ from "lodash";
import classNames from "classnames";
import LoadingBar from "react-top-loading-bar";
import { SeatByCinemaLocation } from "../interfaces/seatByCinemaLocation";
import OccupancyHeatmap from "./OccupancyHeatmap";

ChartJS.register(ArcElement, ChartJSTooltip, ChartJSLegend);
const animatedComponents = makeAnimated();

let controller: AbortController;
let apiHitCount = 0;
let apiCount = 0;

function Dashboard() {
  const [searchParams, setSearchParams] = useSearchParams();

  const totalApis = 28;
  let { apikey } = useParams();
  let BASE_URL = "";
  if (typeof window !== "undefined" && (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")) {
    BASE_URL = "http://localhost:3030/api/v1";
  } else {
    BASE_URL = "https://api.screensights.io/api/v1";
  }
  const commonHeaders = {
    "X-Api-Key": apikey
  };

  const [apiHitCountState, setApiHitCountState] = useState<number>(0);
  const [showtimeIsCanceled, setShowtimeIsCanceled] = useState<boolean>(false);
  const [movies, setMovies] = useState<any>(null);
  const [selectedMovieIds, setSelectedMovieIds] = useState<number[]>([]);
  const [chains, setChains] = useState<any>(null);
  const [selectedChainIds, setSelectedChainIds] = useState<number[]>([]);
  const [selectedAttributes, setSelectedAttributes] = useState<number[]>([]);
  const [cinemas, setCinemas] = useState<any>(null);
  const [attributes, setAttributes] = useState<any>(null);
  const [selectedCinemaIds, setSelectedCinemaIds] = useState<number[]>([]);
  const [cinemaCountries, setCinemaCountries] = useState<string[]>([]);
  const [selectedCinemaCountries, setSelectedCinemaCountries] = useState<string[]>([]);
  const [showtimeDateRange, setShowtimeDateRange] = useState([null, null]);
  const [showtimeSummary, setShowtimeSummary] = useState<any>({});
  const [showtimeSummaryDraw, setShowtimeSummaryDraw] = useState<number>(0);
  const [seatSummary, setSeatSummary] = useState<any>({});
  const [seatSummaryDraw, setSeatSummaryDraw] = useState<number>(0);
  const [revenueSummary, setRevenueSummary] = useState<any>([]);
  const [revenueSummaryDraw, setRevenueSummaryDraw] = useState<number>(0);
  const [revenueBySeatType, setRevenueBySeatType] = useState<any[]>();
  const [revenueBySeatTypeDraw, setRevenueBySeatTypeDraw] = useState<number>(0);
  const [revenueByChain, setRevenueByChain] = useState<any[]>();
  const [revenueByChainDraw, setRevenueByChainDraw] = useState<number>(0);
  const [revenueByCinema, setRevenueByCinema] = useState<any[]>();
  const [revenueByCinemaDraw, setRevenueByCinemaDraw] = useState<number>(0);
  const [revenueByMovie, setRevenueByMovie] = useState<any[]>();
  const [revenueByMovieDraw, setRevenueByMovieDraw] = useState<number>(0);
  const [revenueByPLF, setRevenueByPLF] = useState<any[]>();
  const [revenueByPLFDraw, setRevenueByPLFDraw] = useState<number>(0);
  const [showtimeByDay, setShowtimeByDay] = useState<any[]>([]);
  const [showtimeByDayDraw, setShowtimeByDayDraw] = useState<number>(0);
  const [showtimeByChain, setShowtimeByChain] = useState<any[]>([]);
  const [showtimeByChainDraw, setShowtimeByChainDraw] = useState<number>(0);
  const [showtimeByCinema, setShowtimeByCinema] = useState<any[]>([]);
  const [showtimeByCinemaDraw, setShowtimeByCinemaDraw] = useState<number>(0);
  const [showtimeByMovie, setShowtimeByMovie] = useState<any[]>([]);
  const [showtimeByMovieDraw, setShowtimeByMovieDraw] = useState<number>(0);
  const [showtimeByHour, setShowtimeByHour] = useState<any[]>([]);
  const [showtimeByHourDraw, setShowtimeByHourDraw] = useState<number>(0);
  const [showtimeByPLF, setShowtimeByPLF] = useState<any[]>([]);
  const [showtimeByPLFDraw, setShowtimeByPLFDraw] = useState<number>(0);
  const [occupancyByDay, setOccupancyByDay] = useState<any[]>([]);
  const [occupancyByDayDraw, setOccupancyByDayDraw] = useState<number>(0);
  const [seatByDay, setSeatByDay] = useState<any[]>([]);
  const [seatByDayDraw, setSeatByDayDraw] = useState<number>(0);
  const [seatByChain, setSeatByChain] = useState<any[]>([]);
  const [seatByChainDraw, setSeatByChainDraw] = useState<number>(0);
  const [seatByCinema, setSeatByCinema] = useState<any[]>([]);
  const [seatByCinemaDraw, setSeatByCinemaDraw] = useState<number>(0);
  const [seatByMovie, setSeatByMovie] = useState<any[]>([]);
  const [seatByMovieDraw, setSeatByMovieDraw] = useState<number>(0);
  const [seatByStatus, setSeatByStatus] = useState<any[]>([]);
  const [seatByStatusDraw, setSeatByStatusDraw] = useState<number>(0);
  const [seatByPLF, setSeatByPLF] = useState<any[]>([]);
  const [seatByPLFDraw, setSeatByPLFDraw] = useState<number>(0);
  const [showtimes, setShowtimes] = useState<any[]>([]);
  const [showtimesDraw, setShowtimesDraw] = useState<number>(0);
  const [showtimesPage, setShowtimesPage] = useState<number>(1);
  const [showtimesPerPage, setShowtimesPerPage] = useState<number>(15);
  const [seatByCinemaLocation, setSeatByCinemaLocation] = useState<SeatByCinemaLocation[]>([]);
  const [seatByCinemaLocationDraw, setSeatByCinemaLocationDraw] = useState<number>(0);
  const [apiCountState, setApiCountState] = useState(0);

  const buildFilterParam = (exclude?: string, extraFilters: any = {}) => {
    return {
      ...(exclude === "movie_ids" ? {} : { movie_ids: selectedMovieIds }),
      ...(exclude === "chain_ids" ? {} : { chain_ids: selectedChainIds }),
      ...(exclude === "cinema_ids" ? {} : { cinema_ids: selectedCinemaIds }),
      ...(exclude === "cinema_countries" ? {} : { cinema_countries: selectedCinemaCountries }),
      ...(exclude === "attributes" ? {} : { attributes: selectedAttributes }),
      showtime_from: showtimeDateRange?.[0] ? momentCore(showtimeDateRange?.[0]).format("YYYY-MM-DD") : undefined,
      showtime_to: showtimeDateRange?.[1] ? momentCore(showtimeDateRange?.[1]).format("YYYY-MM-DD") : undefined,
      showtime_is_canceled: showtimeIsCanceled,
      cache: true,
      limit: 1000,
      ...extraFilters
    };
  };
  const fetchFromAPI = (endpoint: string, excludeParam: string, cb: Function, extraFilters = {}, _apiHitCount: number, cbDraw?: Function, updateApiCountState?: boolean) => {
    (function tryFetch(retry: number = 1) {
      axios.get(`${BASE_URL}${endpoint}`, {
        withCredentials: false,
        headers: {
          ...commonHeaders
        },
        signal: controller.signal,
        timeout: 5 * 60 * 1000,
        params: buildFilterParam(excludeParam, extraFilters)
      })
        .then(function({ data }) {
          if ((_apiHitCount === 0 || _apiHitCount === apiHitCount)) {
            cb(processNumbers(data));
            if (cbDraw) {
              cbDraw(apiHitCount);
            }
            if (updateApiCountState) {
              apiCount += 1;
              setApiCountState(apiCount);
            }
          }
        })
        .catch(function(error) {
          if (!axios.isCancel(error)) {
            console.error(error);
            if (retry <= 3) {
              setTimeout(function() {
                  tryFetch(retry++);
                }, _.random(1000, 10000)
              );
            }
          }
        });
    })();
  };

  const fetchAllData = () => {
    apiCount = 0;
    setApiCountState(0);
    apiHitCount += 1;
    setApiHitCountState(apiHitCount);
    controller = new AbortController();
    fetchFromAPI("/dashboard/showtime-summary", "", setShowtimeSummary, {}, apiHitCount, setShowtimeSummaryDraw, true);
    fetchFromAPI("/dashboard/seat-summary", "", setSeatSummary, {}, apiHitCount, setSeatSummaryDraw, true);
    fetchFromAPI("/dashboard/chains", "chain_ids", setChains, {}, apiHitCount, undefined, true);
    fetchFromAPI("/dashboard/cinemas", "cinema_ids", setCinemas, {}, apiHitCount, undefined, true);
    fetchFromAPI("/dashboard/cinema-countries", "cinema_countries", setCinemaCountries, {}, apiHitCount, undefined, true);
    fetchFromAPI("/dashboard/movies", "movie_ids", setMovies, {}, apiHitCount, undefined, true);
    fetchFromAPI("/dashboard/plfs", "attributes", setAttributes, {}, apiHitCount, undefined, true);
    fetchFromAPI("/dashboard/revenue-summary", "", setRevenueSummary, {}, apiHitCount, setRevenueSummaryDraw, true);
    fetchFromAPI("/dashboard/showtime-by-day", "", setShowtimeByDay, {}, apiHitCount, setShowtimeByDayDraw, true);
    fetchFromAPI("/dashboard/showtime-by-chain", "", setShowtimeByChain, {}, apiHitCount, setShowtimeByChainDraw, true);
    fetchFromAPI("/dashboard/showtime-by-cinema", "", setShowtimeByCinema, {}, apiHitCount, setShowtimeByCinemaDraw, true);
    fetchFromAPI("/dashboard/showtime-by-movie", "", setShowtimeByMovie, {}, apiHitCount, setShowtimeByMovieDraw, true);
    fetchFromAPI("/dashboard/showtime-by-plf", "", setShowtimeByPLF, {}, apiHitCount, setShowtimeByPLFDraw, true);
    fetchFromAPI("/dashboard/revenue-by-seat-type", "", setRevenueBySeatType, {}, apiHitCount, setRevenueBySeatTypeDraw, true);
    fetchFromAPI("/dashboard/revenue-by-chain", "", setRevenueByChain, {}, apiHitCount, setRevenueByChainDraw, true);
    fetchFromAPI("/dashboard/revenue-by-cinema", "", setRevenueByCinema, {}, apiHitCount, setRevenueByCinemaDraw, true);
    fetchFromAPI("/dashboard/revenue-by-movie", "", setRevenueByMovie, {}, apiHitCount, setRevenueByMovieDraw, true);
    fetchFromAPI("/dashboard/revenue-by-plf", "", setRevenueByPLF, {}, apiHitCount, setRevenueByPLFDraw, true);
    fetchFromAPI("/dashboard/occupancy-by-day", "", setOccupancyByDay, {}, apiHitCount, setOccupancyByDayDraw, true);
    fetchFromAPI("/dashboard/seat-by-day", "", setSeatByDay, {}, apiHitCount, setSeatByDayDraw, true);
    fetchFromAPI("/dashboard/seat-by-status", "", setSeatByStatus, {}, apiHitCount, setSeatByStatusDraw, true);
    fetchFromAPI("/dashboard/seat-by-chain", "", setSeatByChain, {}, apiHitCount, setSeatByChainDraw, true);
    fetchFromAPI("/dashboard/seat-by-cinema", "", setSeatByCinema, {}, apiHitCount, setSeatByCinemaDraw, true);
    fetchFromAPI("/dashboard/seat-by-movie", "", setSeatByMovie, {}, apiHitCount, setSeatByMovieDraw, true);
    fetchFromAPI("/dashboard/seat-by-plf", "", setSeatByPLF, {}, apiHitCount, setSeatByPLFDraw, true);
    fetchFromAPI("/dashboard/seat-by-cinema-location", "", setSeatByCinemaLocation, {}, apiHitCount, setSeatByCinemaLocationDraw, true);
    fetchFromAPI("/dashboard/showtime-by-hour", "", setShowtimeByHour, {}, apiHitCount, setShowtimeByHourDraw, true);//slow
    fetchFromAPI("/dashboard/showtimes", "", setShowtimes, {
      page: showtimesPage,
      limit: showtimesPerPage
    }, apiHitCount, setShowtimesDraw, true);
    setShowtimesPage(1);
  };

  useEffect(() => {
    controller = new AbortController();
    fetchFromAPI("/dashboard/showtimes", "", setShowtimes, {
      page: showtimesPage,
      limit: showtimesPerPage
    }, apiHitCount, setShowtimesDraw, false);
  }, [showtimesPage, showtimesPerPage]);

  useEffect(() => {
    fetchAllData();
  }, [selectedMovieIds, selectedChainIds, selectedCinemaIds, selectedCinemaCountries, showtimeDateRange, showtimeIsCanceled, selectedAttributes]);

  function processNumbers(inputData: any) {
    if (Array.isArray(inputData)) {
      inputData.forEach(item => processNumbers(item));
    } else if (typeof inputData === "object" && inputData !== null) {
      for (let key in inputData) {
        if (inputData.hasOwnProperty(key)) {
          let value = inputData[key];
          let parsedValue = Number(value);
          if (!isNaN(parsedValue) && isFinite(value)) {
            inputData[key] = parsedValue;
          }
        }
      }
    }
    return inputData;
  }

  const displayNumber = (data: string | number) => {
    let numberFormat = "0a";
    if (Number(data) > 10000) {
      numberFormat = "0.0a";
    } else if (Number(data) > 1000) {
      numberFormat = "0.00a";
    }
    return numeral(data).format(numberFormat).toUpperCase();
  };

  const formatPieChartData = (labelKey: string, dataKey: string, data: any[], maxItems: number = 10) => {
    const sortedData = data?.sort((a: any, b: any) => {
      return Number(b?.[dataKey]) - Number(a?.[dataKey]);
    })?.slice(0, maxItems);

    return {
      labels: sortedData?.map(s => `${s?.[labelKey] || "Unknown"}`), data: sortedData?.map(s => Number(s?.[dataKey]))
    };
  };


  const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "red", "pink", "rgba(153, 102, 255, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(255, 159, 64, 1)",
    "#8AAF22",
    "#8871A0",
    "#3F9F9F",
    "rgba(255, 206, 86, 1)",
    "#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"
  ];

  const totalPages = Math.ceil(showtimeSummary?._total_showtimes / showtimesPerPage);

  function generatePageNumbers(count: number, current: number): (string | number)[] {
    const shownPages = 3;
    const result = [];
    if (current > count - shownPages) {
      result.push(count - 2, count - 1, count);
    } else {
      result.push(current, current + 1, current + 2, "...", count);
    }
    return result;
  }

  const pageNumbers = generatePageNumbers(totalPages, showtimesPage);

  return (
    <>
      <LoadingBar
        color="#0072f0"
        progress={Math.round((apiCountState / totalApis) * 100)}
        onLoaderFinished={() => {
          apiCount = 0;
          setApiCountState(0);
        }}
        style={{ height: "5px" }}
      />
      <Container fluid={true} className="px-lg-5 mt-5">
        <Row className={"mb-5"}>
          <Col xs={12}>
            <h2 className="chart-group-title">
              Filters
            </h2>
          </Col>
          <Col md={3} sm={6}>
            <FormGroup>
              <Label>Chain</Label>
              <Select onChange={(value) => {
                if (controller) {
                  controller.abort("Parameter changed");
                }
                setSelectedChainIds((value as any)?.map((v: any) => v?.value) || []);
              }}
                      options={chains?.map((chain: any) => ({
                        value: chain?.chain_id,
                        label: `${chain?.chain_name} (${chain?._total_showtimes})`
                      })) || []}
                      components={animatedComponents} isMulti />
            </FormGroup>
          </Col>
          <Col md={3} sm={6}>
            <FormGroup>
              <Label>Cinema</Label>
              <Select onChange={(value) => {
                if (controller) {
                  controller.abort("Parameter changed");
                }
                setSelectedCinemaIds((value as any)?.map((v: any) => v?.value) || []);
              }}
                      options={cinemas?.map((cinema: any) => ({
                        value: cinema?.cinema_id,
                        label: `${cinema?.cinema_name} (${cinema?._total_showtimes})`
                      })) || []}
                      components={animatedComponents} isMulti />
            </FormGroup>
          </Col>
          <Col md={3} sm={6}>
            <FormGroup>
              <Label>Cinema Country</Label>
              <Select onChange={(value) => {
                if (controller) {
                  controller.abort("Parameter changed");
                }
                setSelectedCinemaCountries((value as any)?.map((v: any) => v?.value) || []);
              }} options={cinemaCountries?.map((cinemaCountry: any) => ({
                value: cinemaCountry.cinema_country,
                label: `${cinemaCountry.cinema_country} (${cinemaCountry?._total_showtimes})`
              })) || []}
                      components={animatedComponents} isMulti />
            </FormGroup>
          </Col>
          <Col md={3} sm={6}>
            <FormGroup>
              <Label>Movie</Label>
              <Select onChange={(value) => {
                if (controller) {
                  controller.abort("Parameter changed");
                }
                setSelectedMovieIds((value as any)?.map((v: any) => v?.value) || []);
              }} options={movies?.map((movie: any) => ({
                value: movie?.movie_id, label: `${movie?.movie_title} (${movie?._total_showtimes})`
              })) || []}
                      components={animatedComponents} isMulti />
            </FormGroup>
          </Col>
          <Col md={3} sm={6}>
            <FormGroup>
              <Label className="small">Showtimes by premium large format (3D, IMAX, etc.)</Label>
              <Select onChange={(value) => {
                if (controller) {
                  controller.abort("Parameter changed");
                }
                setSelectedAttributes((value as any)?.map((v: any) => v?.value) || []);
              }} options={attributes?.map((attribute: any) => ({
                value: attribute?._attribute, label: `${attribute?._attribute} (${attribute?._total_showtimes})`
              })) || []}
                      components={animatedComponents} isMulti />
            </FormGroup>
          </Col>
          <Col md={3} sm={6}>
            <FormGroup>
              <Label>Showtime date range</Label>
              <DatePicker
                onChange={(date: any) => {
                  if (controller) {
                    controller.abort("Parameter changed");
                  }
                  setShowtimeDateRange(date);
                }}
                isClearable
                selectsRange={true}
                startDate={showtimeDateRange?.[0]}
                endDate={showtimeDateRange?.[1]}
                className="form-control"
              />
            </FormGroup>
          </Col>
          <Col md={3} sm={6}>
            <FormGroup>
              <Label>Showtime status
                <span id="showtimes_status" className={"info-box"}>?</span>
                <UncontrolledTooltip style={{ minWidth: "300px" }} placement="top" target="showtimes_status">
                  We consider showtimes as canceled when this information is provided by the cinema or when a showtime
                  is removed from the cinema's website for more than 24 hours.
                </UncontrolledTooltip>
              </Label>
              <Select
                isMulti={false}
                defaultValue={{ label: "Active", value: false }}
                onChange={(value) => {
                  if (controller) {
                    controller.abort("Parameter changed");
                  }
                  setShowtimeIsCanceled((value as any)?.value as boolean);

                }} options={[{
                value: true, label: "Canceled"
              }, {
                value: false, label: "Active"
              }]}
                components={animatedComponents} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h2 className="chart-group-title">
              High-Level Insights
            </h2>
          </Col>
          <Col lg={3} md={4} sm={6}>
            <div className={classNames("score-card-container", {
              "outdated-value": showtimeSummaryDraw !== apiHitCountState
            })}>
              <div className="score-card-title">Chains</div>
              <div className="score-card-value"> {displayNumber(showtimeSummary?._total_chains)}</div>
            </div>
          </Col>
          <Col lg={3} md={4} sm={6}>
            <div className={classNames("score-card-container", {
              "outdated-value": showtimeSummaryDraw !== apiHitCountState
            })}>
              <div className="score-card-title">Cinemas</div>
              <div className="score-card-value"> {displayNumber(showtimeSummary?._total_cinemas)}</div>
            </div>
          </Col>
          <Col lg={3} md={4} sm={6}>
            <div className={classNames("score-card-container", {
              "outdated-value": showtimeSummaryDraw !== apiHitCountState
            })}>
              <div className="score-card-title">Movies</div>
              <div className="score-card-value"> {displayNumber(showtimeSummary?._total_movies)}</div>
            </div>
          </Col>
          <Col lg={3} md={4} sm={6}>
            <div className={classNames("score-card-container", {
              "outdated-value": showtimeSummaryDraw !== apiHitCountState
            })}>
              <div className="score-card-title">Screens</div>
              <div className="score-card-value"> {displayNumber(showtimeSummary?._total_screens)}</div>
            </div>
          </Col>
          <Col lg={3} md={4} sm={6}>
            <div className={classNames("score-card-container", {
              "outdated-value": showtimeSummaryDraw !== apiHitCountState
            })}>
              <div className="score-card-title">Showtimes</div>
              <div className="score-card-value"> {displayNumber(showtimeSummary?._total_showtimes)}</div>
            </div>
          </Col>
          <Col lg={3} md={4} sm={6}>
            <div className={classNames("score-card-container", {
              "outdated-value": seatSummaryDraw !== apiHitCountState
            })}>
              <div className="score-card-title">Total Seats</div>
              <div className="score-card-value"> {displayNumber(seatSummary?._total_seats)}</div>
            </div>
          </Col>
          <Col lg={3} md={4} sm={6}>
            <div className={classNames("score-card-container", {
              "outdated-value": seatSummaryDraw !== apiHitCountState
            })}>
              <div className="score-card-title">Available Seats</div>
              <div className="score-card-value"> {displayNumber(seatSummary?._available_seats)}</div>
            </div>
          </Col>
          <Col lg={3} md={4} sm={6}>
            <div className={classNames("score-card-container", {
              "outdated-value": seatSummaryDraw !== apiHitCountState
            })}>
              <div className="score-card-title">
                Occupied Seats
              </div>
              <div className={classNames("score-card-value")}>{displayNumber(seatSummary?._occupied_seats)}</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h2 className="chart-group-title">
              Showtime Insights
            </h2>
          </Col>
          <Col md={4}>
            <h4 className="chart-title">Showtimes by date</h4>
            <div className={classNames("chart-container", {
              "outdated-value": showtimeByDayDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={showtimeByDay}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="_showtime_date"
                    name="Date"
                    tickFormatter={_showtime_date => moment.utc(_showtime_date).format("D MMM, YYYY")}
                  />
                  <YAxis tickFormatter={(data) => displayNumber(data)} />
                  <Tooltip labelFormatter={(dt) => {
                    return moment.utc(dt).format("D MMM, YYYY");
                  }} formatter={(data) => {
                    return displayNumber(data as number);
                  }} />
                  <Legend />
                  <Bar dataKey="_total_showtimes" name="Showtimes" fill="#1589FF" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col md={4}>
            <h4 className="chart-title">Showtimes by hour<span id="showtimes_by_hour" className={"info-box"}>?</span>
              <UncontrolledTooltip style={{ minWidth: "300px" }} placement="top" target="showtimes_by_hour">
                Below, you will find the accumulated showtimes organized by the hour of the day, according to your
                specified
                filters. These showtimes represent the cumulative total for the upcoming 59 minutes. For instance, the
                showtimes at 1 PM include all the showtimes from 1:00 PM to 1:59 PM.
              </UncontrolledTooltip>
            </h4>
            <div className={classNames("chart-container", {
              "outdated-value": showtimeByHourDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={showtimeByHour}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="_showtime_hour" name="Hour" />
                  <YAxis tickFormatter={(data) => displayNumber(data)} />
                  <Tooltip formatter={(data) => {
                    return displayNumber(data as number);
                  }} />
                  <Legend />
                  <Bar dataKey="_total_showtimes" name="Showtimes" fill="#1589FF" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col md={4}>
            <h4 className="chart-title">Showtimes by premium large format <span className="small text-muted">(3D, IMAX, etc.)</span>
              <span id="showtimes_by_premium" className={"info-box"}>?</span>
              <UncontrolledTooltip style={{ minWidth: "300px" }} placement="top" target="showtimes_by_premium">
                We currently group all different kinds of experiences under this filter.
                We plan to split the experiences into subcategories like Version → OMU, OV, etc.,
                Seating → VIP, D-BOX, etc., PLF → 3D, IMAX, etc.
                The subcategories will be available by individual filters where you can filter for single versions,
                seating types, etc.
              </UncontrolledTooltip>
            </h4>
            <div className={classNames("chart-container", {
              "outdated-value": showtimeByPLFDraw !== apiHitCountState
            })}>
              <ChartJSPie
                width={"100%"}
                redraw={true}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: "right"
                    }
                  },
                  animation: {
                    duration: 0
                  }
                }}
                data={{
                  labels: formatPieChartData("_attribute", "_total_showtimes", showtimeByPLF).labels, datasets: [{
                    label: "Showtimes",
                    data: formatPieChartData("_attribute", "_total_showtimes", showtimeByPLF).data,
                    backgroundColor: _.shuffle(colors)
                  }]
                }} />
            </div>
          </Col>
          <Col md={4}>
            <h4 className="chart-title">Showtimes by chain</h4>
            <div className={classNames("chart-container large", {
              "outdated-value": showtimeByChainDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  layout="vertical"
                  data={showtimeByChain}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" tickFormatter={(data) => displayNumber(data)} />
                  <YAxis width={120} type="category" dataKey="_chain_name" name="Chain" />
                  <Tooltip formatter={(data) => {
                    return displayNumber(data as number);
                  }} />
                  <Legend />
                  <Bar dataKey="_total_showtimes" name="Showtimes" fill="#1589FF" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col md={4}>
            <h4 className="chart-title">Showtimes by cinema</h4>
            <div className={classNames("chart-container large", {
              "outdated-value": showtimeByCinemaDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  layout="vertical"
                  data={showtimeByCinema}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    type="number"
                    tickFormatter={(data) => displayNumber(data)}
                  />
                  <YAxis
                    width={150}
                    type="category"
                    dataKey="_cinema_name"
                    name="Cinema"
                  />
                  <Tooltip formatter={(data) => {
                    return displayNumber(data as number);
                  }} />
                  <Legend />
                  <Bar dataKey="_total_showtimes" name="Showtimes" fill="#1589FF" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col md={4}>
            <h4 className="chart-title">Showtimes by movie</h4>
            <div className={classNames("chart-container large", {
              "outdated-value": showtimeByMovieDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={showtimeByMovie}
                  layout="vertical"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    type={"number"}
                    tickFormatter={(data) => displayNumber(data)}
                  />
                  <YAxis
                    width={120}
                    type="category"
                    dataKey="_movie_title"
                    name="Movie"
                  />
                  <Tooltip formatter={(data) => {
                    return displayNumber(data as number);
                  }} />
                  <Legend />
                  <Bar dataKey="_total_showtimes" name="Showtimes" fill="rgb(0, 114, 240)" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h2 className="chart-group-title">
              Occupancy Insights
            </h2>
          </Col>
          <Col md={4}>
            <h4 className="chart-title">Occupancy by date</h4>
            <div className={classNames("chart-container", {
              "outdated-value": seatByDayDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={seatByDay}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="_showtime_date"
                    name="Date"
                    tickFormatter={_showtime_date => moment.utc(_showtime_date).format("D MMM, YYYY")}
                  />
                  <YAxis tickFormatter={(data) => displayNumber(data)} />
                  <Tooltip labelFormatter={(dt) => {
                    return moment.utc(dt).format("D MMM, YYYY");
                  }} formatter={(data) => {
                    return displayNumber(data as number);
                  }} />
                  <Legend />
                  <Bar stackId="a" dataKey="_occupied_seats" name="Occupied Seats" fill="#0000A0" />
                  {/*<Bar stackId="a" dataKey="_suspicious_occupied_seats" name="Suspicious Occupied Seats"
                             fill="#0000c7" />*/}
                  <Bar stackId="a" dataKey="_available_seats" name="Availble Seats" fill="#1589FF" />
                  <Bar stackId="a" dataKey="_blocked_seats" name="Blocked Seats" fill="#EF5350" />
                  <Bar stackId="a" dataKey="_unknown_seats" name="Unknown Seats" fill="#FF9800" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col md={4}>
            <h4 className="chart-title">Occupancy by hour<span id="occupancy_by_hour" className={"info-box"}>?</span>
              <UncontrolledTooltip style={{ minWidth: "300px" }} placement="top" target="occupancy_by_hour">
                Below, you will find the accumulated occupancy organized by the hour of the day, according to your
                specified
                filters. The occupancy represents the cumulative total for the upcoming 59 minutes. For instance, the
                occupancy at 1 PM includes the total occupancy of all screenings from 1:00 PM to 1:59 PM.
              </UncontrolledTooltip>
            </h4>
            <div className={classNames("chart-container", {
              "outdated-value": showtimeSummaryDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={showtimeByHour}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="_showtime_hour" name="Hour" />
                  <YAxis tickFormatter={(data) => displayNumber(data)} />
                  <Tooltip formatter={(data) => {
                    return displayNumber(data as number);
                  }} />
                  <Legend />
                  <Bar stackId="a" dataKey="_occupied_seats" name="Occupied Seats" fill="#0000A0" />
                  {/*<Bar stackId="a" dataKey="_suspicious_occupied_seats" name="Suspicious Occupied Seats"
                       fill="#0000c7" />*/}
                  <Bar stackId="a" dataKey="_available_seats" name="Availble Seats" fill="#1589FF" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col md={4}>
            <h4 className="chart-title">Occupancy by premium large format <span className="small text-muted">(3D, IMAX, etc.)</span>
            </h4>
            <div className={classNames("chart-container", {
              "outdated-value": seatByPLFDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  layout="vertical"
                  data={seatByPLF}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    tickFormatter={(data) => displayNumber(data)}
                    type={"number"}
                  />
                  <YAxis
                    width={150}
                    type="category"
                    dataKey="_attribute"
                    name="PLF"
                  />
                  <Tooltip formatter={(data) => {
                    return displayNumber(data as number);
                  }} />
                  <Legend />
                  <Bar stackId="a" dataKey="_occupied_seats" name="Occupied Seats" fill="#0000A0" />
                  {/*<Bar stackId="a" dataKey="_suspicious_occupied_seats" name="Suspicious Occupied Seats"
                       fill="#0000c7" />*/}
                  <Bar stackId="a" dataKey="_available_seats" name="Availble Seats" fill="#1589FF" />
                  <Bar stackId="a" dataKey="_blocked_seats" name="Blocked Seats" fill="#EF5350" />
                  <Bar stackId="a" dataKey="_unknown_seats" name="Unknown Seats" fill="#FF9800" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col md={4}>
            <h4 className="chart-title">Occupancy by chain</h4>
            <div className={classNames("chart-container large", {
              "outdated-value": seatByChainDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  layout="vertical"
                  data={seatByChain}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" tickFormatter={(data) => displayNumber(data)} />
                  <YAxis width={120} type="category" dataKey="_chain_name" name="Chain" />
                  <Tooltip formatter={(data) => {
                    return displayNumber(data as number);
                  }} />
                  <Legend />
                  <Bar stackId="a" dataKey="_occupied_seats" name="Occupied Seats" fill="#0000A0" />
                  {/*<Bar stackId="a" dataKey="_suspicious_occupied_seats" name="Suspicious Occupied Seats"
                       fill="#0000c7" />*/}
                  <Bar stackId="a" dataKey="_available_seats" name="Availble Seats" fill="#1589FF" />
                  <Bar stackId="a" dataKey="_blocked_seats" name="Blocked Seats" fill="#EF5350" />
                  <Bar stackId="a" dataKey="_unknown_seats" name="Unknown Seats" fill="#FF9800" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col md={4}>
            <h4 className="chart-title">Occupancy by cinema</h4>
            <div className={classNames("chart-container large", {
              "outdated-value": seatByCinemaDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  layout="vertical"
                  data={seatByCinema}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    type="number"
                    tickFormatter={(data) => displayNumber(data)}
                  />
                  <YAxis
                    width={150}
                    type="category"
                    dataKey="_cinema_name"
                    name="Cinema"
                  />
                  <Tooltip formatter={(data) => {
                    return displayNumber(data as number);
                  }} />
                  <Legend />
                  <Bar stackId="a" dataKey="_occupied_seats" name="Occupied Seats" fill="#0000A0" />
                  {/*<Bar stackId="a" dataKey="_suspicious_occupied_seats" name="Suspicious Occupied Seats"
                       fill="#0000c7" />*/}
                  <Bar stackId="a" dataKey="_available_seats" name="Availble Seats" fill="#1589FF" />
                  <Bar stackId="a" dataKey="_blocked_seats" name="Blocked Seats" fill="#EF5350" />
                  <Bar stackId="a" dataKey="_unknown_seats" name="Unknown Seats" fill="#FF9800" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col md={4}>
            <h4 className="chart-title">Occupancy by movie</h4>
            <div className={classNames("chart-container large", {
              "outdated-value": seatByMovieDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={seatByMovie}
                  layout="vertical"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    type={"number"}
                    tickFormatter={(data) => displayNumber(data)}
                  />
                  <YAxis
                    width={120}
                    type="category"
                    dataKey="_movie_title"
                    name="Movie"
                  />
                  <Tooltip formatter={(data) => {
                    return displayNumber(data as number);
                  }} />
                  <Legend />
                  <Bar stackId="a" dataKey="_occupied_seats" name="Occupied Seats" fill="#0000A0" />
                  {/*<Bar stackId="a" dataKey="_suspicious_occupied_seats" name="Suspicious Occupied Seats"
                       fill="#0000c7" />*/}
                  <Bar stackId="a" dataKey="_available_seats" name="Availble Seats" fill="#1589FF" />
                  <Bar stackId="a" dataKey="_blocked_seats" name="Blocked Seats" fill="#EF5350" />
                  <Bar stackId="a" dataKey="_unknown_seats" name="Unknown Seats" fill="#FF9800" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col xs={12}>
            <h4 className="chart-title">Seat booking times<span id="seat_booking_times" className={"info-box"}>?</span>
              <UncontrolledTooltip style={{ minWidth: "300px" }} placement="top" target="seat_booking_times">
                The chart below illustrates the timeframes during which seats were booked.
              </UncontrolledTooltip>
            </h4>
            <div className={classNames("chart-container large", {
              "outdated-value": occupancyByDayDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={occupancyByDay}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="_estimated_booking_date"
                    name="Date"
                    tickFormatter={_estimated_booking_date => moment.utc(_estimated_booking_date).format("D MMM, YYYY")}
                  />
                  <Tooltip labelFormatter={(dt) => {
                    return moment.utc(dt).format("D MMM, YYYY");
                  }} formatter={(data) => {
                    return displayNumber(data as number);
                  }} />
                  <YAxis tickFormatter={(data) => displayNumber(data)} />
                  <Legend />
                  <Line type="monotone" dataKey="_occupied_seats" name="Occupied Seats" stroke="#0000c7"
                        strokeWidth={3} />
                  {/*<Line type="monotone" dataKey="_suspicious_occupied_seats" name="Suspicious Occupied Seats"
                        stroke="#1589FF"
                        strokeWidth={3} />*/}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col xs={12}>
            <div>
              <h4 className="chart-title mb-4">Occupancy by Location</h4>
              <div className={classNames("mb-5 pb-4", {
                "outdated-value": seatByCinemaLocationDraw !== apiHitCountState
              })}>
                <OccupancyHeatmap data={seatByCinemaLocation} />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h2 className="chart-group-title">
              Seating Insights
            </h2>
          </Col>
          <Col lg={3} sm={4}>
            <h4 className="chart-title">Seats by status</h4>
            <div className={classNames("chart-container", {
              "outdated-value": seatSummaryDraw !== apiHitCountState
            })}>
              <ChartJSPie
                width={"100%"}
                redraw={true}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: "right"
                    }
                  }
                }}
                data={{
                  labels: [
                    "Occupied",
                    //"Suspicious Occupied",
                    "Available",
                    "Blocked",
                    "Unknown"
                  ], datasets: [{
                    label: "Seats",
                    data: [
                      seatSummary?._occupied_seats,
                      //seatSummary?._suspicious_occupied_seats,
                      seatSummary?._available_seats,
                      seatSummary?._blocked_seats,
                      seatSummary?._unknown_seats
                    ],
                    backgroundColor: ["#0000A0", /*"#0000c7",*/ "#1589FF", "#EF5350", "#FF9800"]
                  }]
                }} />
            </div>
          </Col>
          <Col lg={6} sm={4}>
            <h4 className="chart-title">Seat types by occupancy status</h4>
            <div className={classNames("chart-container", {
              "outdated-value": seatByStatusDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={seatByStatus}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="_seat_type"
                    name="Seat type"
                  />
                  <YAxis tickFormatter={(data) => displayNumber(data)} />
                  <Tooltip formatter={(data) => {
                    return displayNumber(data as number);
                  }} />
                  <Legend />
                  <Bar stackId="a" dataKey="_occupied_seats" name="Occupied Seats" fill="#0000A0" />
                  {/*<Bar stackId="a" dataKey="_suspicious_occupied_seats" name="Suspicious Occupied Seats"
                       fill="#0000c7" />*/}
                  <Bar stackId="a" dataKey="_available_seats" name="Availble Seats" fill="#1589FF" />
                  <Bar stackId="a" dataKey="_blocked_seats" name="Blocked Seats" fill="#EF5350" />
                  <Bar stackId="a" dataKey="_unknown_seats" name="Unknown Seats" fill="#FF9800" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col lg={3} sm={4}>
            <h4 className="chart-title">Seat types</h4>
            <div className={classNames("chart-container", {
              "outdated-value": seatByStatusDraw !== apiHitCountState
            })}>
              <ChartJSPie
                width={"100%"}
                redraw={true}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: "right"
                    }
                  }
                }}
                data={{
                  labels: formatPieChartData("_seat_type", "_total_seats", seatByStatus).labels,
                  datasets: [{
                    label: "Seats",
                    data: formatPieChartData("_seat_type", "_total_seats", seatByStatus).data,
                    backgroundColor: _.shuffle(colors)
                  }]
                }} />
            </div>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xs={12}>
            <h2 className="chart-group-title">
              Revenue Insights
            </h2>
          </Col>
          <Col sm={6}>
            <div
              className={classNames("score-card-container small potential-card", {
                "outdated-value": revenueSummaryDraw !== apiHitCountState
              })}
            >
              <div className="score-card-title">Potential Revenue<span id="rotential_revenue"
                                                                       className={"info-box"}>?</span>
                <UncontrolledTooltip style={{ minWidth: "300px" }} placement="top" target="rotential_revenue">
                  The potential revenue calculates the total count of available seats, based on your specified filter
                  criteria, by the seat or ticket price. We prioritize using the actual seat price if available;
                  otherwise,
                  we use the first price listed in the cinema website's ticket price overview, which typically
                  corresponds
                  to the highest price. If you require customization in this pricing approach, we can accommodate your
                  preferences upon request. Additionally, our API provides access to all available prices, allowing you
                  to
                  conduct your own pricing analysis.
                </UncontrolledTooltip>
              </div>
              {revenueSummary?.map((o: any, index: number) => {
                return <div key={index} className="score-card-value"><span
                  className={"currency-label"}>{index > 0 ? "+" : ""}</span>{` `}{displayNumber(o?._potential_revenue)}
                  {` `}<span
                    className="currency-label">{o?.revenue_currency}</span></div>;
              })}
            </div>
          </Col>
          <Col sm={6}>
            <div
              className={classNames("score-card-container small estimated-card", {
                "outdated-value": revenueSummaryDraw !== apiHitCountState
              })}
            >
              <div className="score-card-title">Estimated Revenue <span id="estimated_revenue"
                                                                        className={"info-box"}>?</span>
                <UncontrolledTooltip style={{ minWidth: "300px" }} placement="top" target="estimated_revenue">
                  The estimated revenue calculates the total count of booked seats, based on your specified filter
                  criteria,
                  by the ticket price available on the cinema website. We prioritize using the actual seat price if
                  available; otherwise, we use the first price listed in the cinema website's ticket price overview,
                  which
                  typically corresponds to the highest price. Custom pricing adjustments are available upon request.
                  Additionally, our API provides access to all available prices, allowing you to conduct your own
                  pricing
                  analysis.
                </UncontrolledTooltip>
              </div>
              {revenueSummary?.map((o: any, index: number) => {
                return <div key={index} className="score-card-value">
                  <span className={"currency-label"}>{index > 0 ? "+" : ""}</span>
                  {` `} {displayNumber(o?._estimated_revenue)}
                  {/*{` `}
                  {o?._suspicious_estimated_revenue > 0 ? <span
                    className="suspicious_rev">+{` `}{displayNumber(o?._suspicious_estimated_revenue)}</span> : ""}*/}
                  {` `} <span className="currency-label">{o?.revenue_currency}</span>
                </div>;
              })}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <h4 className="chart-title">Revenue by seat type</h4>
            <div
              className={classNames("chart-container large", {
                "outdated-value": revenueBySeatTypeDraw !== apiHitCountState
              })}
            >
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={revenueBySeatType}
                  layout="vertical"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <YAxis
                    width={120}
                    type="category"
                    dataKey="_seat_type"
                    name="Seat type"
                  />
                  <XAxis type={"number"} tickFormatter={(data) => displayNumber(data)} />
                  <Tooltip formatter={(data, name, item) => {
                    return `${displayNumber(data as number)} ${item?.payload?.revenue_currency}`;
                  }} />
                  <Legend />
                  <Bar stackId="a" dataKey="_estimated_revenue" name="Estimated Revenue" fill="#008000" />
                  {/*<Bar stackId="a" dataKey="_suspicious_estimated_revenue" name="Suspicious Estimated Revenue"
                       fill="#0BA43B" />*/}
                  <Bar stackId="b" dataKey="_potential_revenue" name="Potential Revenue" fill="#FFA500" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col sm={4}>
            <h4 className="chart-title">Revenue by premium large format <span
              className="small text-muted">(3D, IMAX, etc.)</span></h4>
            <div className={classNames("chart-container large", {
              "outdated-value": revenueByPLFDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={revenueByPLF}
                  layout="vertical"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <YAxis
                    width={150}
                    type="category"
                    dataKey="_attribute"
                    name="PLF"
                  />
                  <XAxis type={"number"} tickFormatter={(data) => displayNumber(data)} />
                  <Tooltip formatter={(data, name, item) => {
                    return `${displayNumber(data as number)} ${item?.payload?.revenue_currency}`;
                  }} />
                  <Legend />
                  <Bar stackId="a" dataKey="_estimated_revenue" name="Estimated Revenue" fill="#008000" />
                  {/*<Bar stackId="a" dataKey="_suspicious_estimated_revenue" name="Suspicious Estimated Revenue"
                       fill="#0BA43B" />*/}
                  <Bar stackId="b" dataKey="_potential_revenue" name="Potential Revenue" fill="#FFA500" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col sm={4}>
            <h4 className="chart-title">Revenue by cinema</h4>
            <div className={classNames("chart-container large", {
              "outdated-value": revenueByCinemaDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={revenueByCinema}
                  layout="vertical"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <YAxis
                    width={150}
                    type="category"
                    dataKey="_cinema_name"
                    name="Cinema"
                  />
                  <XAxis type={"number"} tickFormatter={(data) => displayNumber(data)} />
                  <Tooltip formatter={(data, name, item) => {
                    return `${displayNumber(data as number)} ${item?.payload?.revenue_currency}`;
                  }} />
                  <Legend />
                  <Bar stackId="a" dataKey="_estimated_revenue" name="Estimated Revenue" fill="#008000" />
                  {/*<Bar stackId="a" dataKey="_suspicious_estimated_revenue" name="Suspicious Estimated Revenue"
                       fill="#0BA43B" />*/}
                  <Bar stackId="b" dataKey="_potential_revenue" name="Potential Revenue" fill="#FFA500" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col sm={6}>
            <h4 className="chart-title">Revenue by chain</h4>
            <div className={classNames("chart-container large", {
              "outdated-value": revenueByChainDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={revenueByChain}
                  layout="vertical"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <YAxis
                    width={120}
                    type="category"
                    dataKey="_chain_name"
                    name="Chain"
                  />
                  <XAxis type={"number"} tickFormatter={(data) => displayNumber(data)} />
                  <Tooltip formatter={(data, name, item) => {
                    return `${displayNumber(data as number)} ${item?.payload?.revenue_currency}`;
                  }} />
                  <Legend />
                  <Bar stackId="a" dataKey="_estimated_revenue" name="Estimated Revenue" fill="#008000" />
                  {/*<Bar stackId="a" dataKey="_suspicious_estimated_revenue" name="Suspicious Estimated Revenue"
                       fill="#0BA43B" />*/}
                  <Bar stackId="b" dataKey="_potential_revenue" name="Potential Revenue" fill="#FFA500" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col xs={6}>
            <h4 className="chart-title">Revenue by movie</h4>
            <div className={classNames("chart-container large", {
              "outdated-value": revenueByMovieDraw !== apiHitCountState
            })}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  layout="vertical"
                  data={revenueByMovie}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <YAxis
                    width={150}
                    type="category"
                    dataKey="_movie_title"
                    name="Movie"
                  />
                  <XAxis type={"number"} tickFormatter={(data) => displayNumber(data)} />
                  <Tooltip formatter={(data, name, item) => {
                    return `${displayNumber(data as number)} ${item?.payload?.revenue_currency}`;
                  }} />
                  <Legend />
                  <Bar stackId="a" dataKey="_estimated_revenue" name="Estimated Revenue" fill="#008000" />
                  {/*<Bar stackId="a" dataKey="_suspicious_estimated_revenue" name="Suspicious Estimated Revenue"
                       fill="#0BA43B" />*/}
                  <Bar stackId="b" dataKey="_potential_revenue" name="Potential Revenue" fill="#FFA500" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
        </Row>

        <div>
          <h4 className="chart-title text-center mb-4">Occupancy/Screen insights</h4>
          <div className={classNames({
            "outdated-value": showtimesDraw !== apiHitCountState
          })}>
            <Table className="showtime-table">
              <thead>
              <tr>
                <th>Movie</th>
                <th>Cinema</th>
                <th>Auditorium</th>
                <th>Showtime (Cinema Timezone)</th>
                <th>Last Aggregated</th>
                <th>Total Seats</th>
                <th>Unconfirmed Bookings</th>
                <th>Occupied Seats</th>
                <th>Occupancy Percentage</th>
              </tr>
              </thead>
              <tbody>
              {showtimes?.map((showtime) => {
                const occupied_seats = showtime?.occupied_seats;
                const occupancyPercentFloat = Number(occupied_seats / showtime?.total_seats * 100);
                const occupancyByPercent =
                  occupancyPercentFloat > 1
                      ? +occupancyPercentFloat.toFixed(0)
                      : occupancyPercentFloat > 0
                      ? +occupancyPercentFloat.toFixed(1)
                      : 0;
                return (<tr key={showtime?.id}>
                  <td>
                    {showtime?.movie_title || "Waiting for matching"}
                  </td>
                  <td>
                    {showtime?.cinema_website_url ? <a target="_blank"
                                                       href={showtime?.cinema_website_url}
                                                       rel="noreferrer">{showtime?.cinema_name}</a> : showtime?.cinema_name}
                    <div className="small text-muted">
                      <a rel="noreferrer" target="_blank" href={`https://www.google.com/maps?q=${showtime?.latitude},${showtime?.longitude}`}>{showtime?.cinema_address || showtime?.cinema_city}</a>
                    </div>
                  </td>
                  <td>
                    {showtime?.screen_name}
                  </td>
                  <td>
                    <a target="_blank" title={`UTC: ${momentCore(showtime?.start_time)?.format("LLL")}`} href={showtime?.booking_url}
                       rel="noreferrer"> {momentCore(showtime?.start_time).tz(showtime?.cinema_timezone)?.format("LLL") || `${momentCore(showtime?.start_time)?.format("LLL")} (UTC)`}</a>
                  </td>
                  <td title={showtime?.last_crawled_at ? momentCore(showtime?.last_crawled_at)?.format("LLL") : ""}>
                    {showtime?.last_crawled_at ? momentCore(showtime?.last_crawled_at)?.fromNow() : ""}
                  </td>
                  <td>
                    {showtime?.total_seats}
                  </td>
                  <td>
                    {showtime?.suspicious_occupied_seats}
                  </td>
                  <td>
                    {occupied_seats}
                  </td>
                  <td>
                    {occupancyByPercent > 0 ? `${occupancyByPercent}%` : ""}
                  </td>
                </tr>);
              })}
              </tbody>
            </Table>
            <Row>
              <Col className="">
                <Pagination>
                  <PaginationItem disabled={showtimesPage === 1}>
                    <PaginationLink
                      first
                      onClick={() => {
                        setShowtimesPage(1);
                      }}
                    >
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem disabled={showtimesPage === 1}>
                    <PaginationLink
                      previous
                      onClick={() => {
                        setShowtimesPage(showtimesPage - 1);
                      }}
                    >
                    </PaginationLink>
                  </PaginationItem>
                  {pageNumbers?.map((pageNumber, index) => {
                    return (<PaginationItem
                      key={index}
                      active={showtimesPage === pageNumber}
                      disabled={isNaN(pageNumber as number)}
                      onClick={() => {
                        if (!isNaN(pageNumber as number)) {
                          setShowtimesPage(pageNumber as number);
                        }
                      }}
                    >
                      <PaginationLink>
                        {pageNumber}
                      </PaginationLink>
                    </PaginationItem>);
                  })}
                  <PaginationItem disabled={showtimesPage === totalPages}>
                    <PaginationLink
                      next
                      onClick={() => {
                        setShowtimesPage(showtimesPage + 1);
                      }}
                    >
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem disabled={showtimesPage === totalPages}>
                    <PaginationLink
                      last
                      onClick={() => {
                        setShowtimesPage(totalPages);
                      }}
                    >
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col>
              <Col className="pb-1 text-end">
                Showing {showtimesPerPage * (showtimesPage - 1) + 1} to {showtimesPerPage * showtimesPage} <span
                className="text-muted">of {displayNumber(showtimeSummary?._total_showtimes)} showtimes</span>
              </Col>
            </Row>
          </div>
        </div>


        <ul className="mt-5">
          <li>System timezone is UTC</li>
        </ul>
      </Container>
    </>
  );
}

export default Dashboard;
